import { jsx as o, jsxs as t } from "react/jsx-runtime";
import { FormControl as b, Select as f, MenuItem as c } from "@mui/material";
import { Grid as g } from "../Grid/Grid.js";
import { Typography as h } from "../Typography/Typography.js";
import { C as s } from "../../../chunk-1-ae05fc7a.js";
function C({
  id: i,
  DropdownIcon: n,
  options: a,
  placeholder: l,
  value: p,
  variant: d,
  sx: u,
  ...m
}) {
  return /* @__PURE__ */ o(b, { variant: d, hiddenLabel: !0, children: /* @__PURE__ */ o(
    f,
    {
      id: i,
      labelId: `${i}-label`,
      displayEmpty: !0,
      IconComponent: (r) => n ? /* @__PURE__ */ o(n, { ...r }) : /* @__PURE__ */ o(s, { ...r }),
      sx: {
        borderRadius: "18px",
        color: "#FFFFFF !important",
        border: "1px solid rgba(255, 255, 255, 0.07)",
        backgroundColor: "rgba(255, 255, 255, 0.07)",
        gap: "8px",
        ".MuiFilledInput-input": {
          padding: "6px 32px 6px 16px",
          borderRadius: "18px !important"
        },
        "&:before": {
          borderBottom: "none"
        },
        "&:after": {
          borderBottom: "none"
        },
        "&:hover:not(.Mui-disabled, .Mui-error):before": {
          borderBottom: "none"
        },
        ".MuiSelect-icon": {
          color: "white"
        },
        "& .MuiGrid-root": {
          fontSize: "12px",
          gap: "8px",
          lineHeight: "24px"
        },
        ...u
      },
      renderValue: (r) => {
        if (!r)
          return l;
        const e = a.find((x) => x.value === r);
        return /* @__PURE__ */ t(
          g,
          {
            container: !0,
            flexDirection: "row",
            gap: 2,
            wrap: "nowrap",
            alignItems: "center",
            children: [
              e == null ? void 0 : e.icon,
              e == null ? void 0 : e.label
            ]
          }
        );
      },
      value: p,
      ...m,
      children: a.map((r) => /* @__PURE__ */ t(
        c,
        {
          value: r.value,
          sx: { display: "flex", gap: 1 },
          children: [
            r.icon,
            /* @__PURE__ */ o(h, { sx: { fontSize: "12px" }, children: r.label })
          ]
        },
        r.id
      ))
    }
  ) });
}
export {
  C as Select
};
