/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "..";
import { CONNECTED, VIEW, IDLE } from "~/constants";
import { metricHelper } from "../../helpers/metricHelper";
import { dnnService, eventServices, subscriberService } from "../../services";
import { Device } from "../../types/device";
import { deviceMetricsList, DeviceMetrics, deviceMetricsListAll, DeviceMetricsData, networkMetricsList } from "../../types/DeviceMetric";
import { IDNN } from "../../types/dnn";
import Radio from "../../types/radio";
import { calcLengths, groupBy } from "~/helpers/utils";
import { fillTimeSeriesData, transformPingMonData } from "../../views/subscribers/graphs/graphHelper";
import {
  EventGraphData,
  TimeLine,
  TimeLineEvent,
  TimelineLog,
  formatDeviceEventData,
  formatEventData,
  formatLogs,
  formatXrangeData,
  syncMetrics,
} from "~/views/subscribers/graphs/TimelineGraphHelper";
import { defaultViewHiddenColumns } from "~/views/subscribers/useSubsciberPanel";

type initState = {
  open: boolean;
  loading: boolean;

  importOpen: boolean;
  detailsOpen: boolean;

  networksData: Array<IDNN>;

  status: string;
  current: null | Device;
  data: Array<Device>;
  hiddenColumns: Array<string>;
  preset: string;

  timelineGraph: {
    eventShow: "all" | "major" | "error";
    showLogs: boolean;
    loading: boolean;
    timelineData: TimeLine;
    timelineEvents: TimeLineEvent[];
    timestamps: [number, any][];
    logs: TimelineLog[];
    deviceEvents: EventGraphData[];
  };
  metrics: DeviceMetricsData;
  radioConnectionsQuality: any[];
  deviceGroupMappingID: { [key: string]: number };
  connectedDeviceGroups: { [key: string]: number };
  pingmon: {
    pingmonMetrics: {
      ping: any[];
      loss: any[];
      jitter: any[];
      raw: any[];
      min: number;
      max: number;
      average: number;
      successRate: number;
    };
    showLogs: boolean;
  };

  currentMetrics: {
    [key: string]: DeviceMetrics;
  };
};

const initialState: initState = {
  open: false,
  loading: true,

  importOpen: false,
  detailsOpen: false,

  networksData: [],
  deviceGroupMappingID: {},
  connectedDeviceGroups: {},

  status: "",
  current: null,
  data: [],
  hiddenColumns: defaultViewHiddenColumns,
  preset: VIEW.DEFAULT_VIEW,

  timelineGraph: {
    eventShow: "major",
    showLogs: false,
    loading: true,
    timelineData: {},
    timelineEvents: [],
    timestamps: [],
    logs: [],
    deviceEvents: [],
  },
  metrics: {
    device_upload_throughput: [],
    device_download_throughput: [],
    sent_bytes: [],
    received_bytes: [],

  },

  radioConnectionsQuality: [],
  pingmon: {
    pingmonMetrics: {
      ping: [],
      loss: [],
      jitter: [],
      raw: [],
      min: 0,
      max: 0,
      average: 0,
      successRate: 0,
    },
    showLogs: false,
  },

  currentMetrics: {},
};

export const fetchDevices = createAsyncThunk(`subscriber/fetchDevices`, async (_, thunk) => {
  try {
    const devices = await subscriberService.getSubscribers();

    const store = thunk.getState() as RootState;
    const radios = store.radio.data;

    return {
      radios,
      devices: devices.sort((a, b) => a.name.localeCompare(b.name)),
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchTimelineGraph = createAsyncThunk(`subscriber/fetchTimelineGraph`, async (id: string, thunk) => {
  const state = thunk.getState() as RootState;
  const { startTime, endTime } = state.utility.time;
  const radioMap = state.radio.radioMap;
  const params = {
    metrics: ["ue_connection_state", "ue_radio_connection"],
    interval: { startTime, endTime },
    resolution: metricHelper.getResolution(startTime, endTime),
    ids: [id],
  };
  const metrics = await subscriberService.getMetrics(params);

  const events = await eventServices.getEvents({
    interval: { startTime, endTime },
    objectId: id,
    objectType: "DEVICE",
  });

  return {
    id,
    data: metrics,
    interval: params.interval,
    resolution: params.resolution,
    radioMap,
    events,
  };
});

/* The above code is a TypeScript function that defines a thunk action creator `fetchDeviceMetrics`
using the `createAsyncThunk` function from the Redux Toolkit. This thunk action is used to fetch
device metrics data for a specific subscriber ID asynchronously. */
export const fetchDeviceMetrics = createAsyncThunk(`subscriber/fetchDeviceMetrics`, async (id: string, thunk) => {
  const state = thunk.getState() as RootState;
  const { startTime, endTime } = state.utility.time;
  const params = {
    metrics: ["device_upload_throughput", "device_download_throughput", "sent_bytes", "received_bytes"],
    interval: { startTime, endTime },
    resolution: metricHelper.getResolution(startTime, endTime),
    ids: [id],
  };
  return {
    id,
    data: await subscriberService.getMetrics(params),
    interval: params.interval,
    resolution: params.resolution,
  };
});
export const fetchConnectionQualityMetrics = createAsyncThunk(
  `subscriber/fetchConnectionQualityMetrics`,
  async (id: string, thunk) => {
    const state = thunk.getState() as RootState;
    const { startTime, endTime } = state.utility.time;
    const params = {
      metrics: deviceMetricsList,
      interval: { startTime, endTime },
      resolution: metricHelper.getResolution(startTime, endTime),
      ids: [id],
    };
    return {
      id,
      data: await subscriberService.getMetrics(params),
      interval: params.interval,
      resolution: params.resolution,
    };
  }
);
export const fetchRadioConnectionsQuality = createAsyncThunk(
  `subscriber/fetchRadioConnectionsQuality`,
  subscriberService.getMetrics
);

let lastRefreshTime = 0;
let prevDeviceID = "";
let prevInterval = 0;
export const fetchPingmon = createAsyncThunk(`subscriber/fetchPingmon`, async (id: string, thunk) => {
  const state = thunk.getState() as RootState;
  const { startTime, endTime, diff } = state.utility.time;
  let newStartTime = startTime;

  // condition for full sync and partial sync
  const syncDiff = endTime - lastRefreshTime;
  if (syncDiff <= 60 && prevDeviceID === id && prevInterval === diff) {
    newStartTime = lastRefreshTime;
  } else {
    lastRefreshTime = 0;
  }

  const params = {
    metrics: ["pingmon"],
    interval: { startTime: newStartTime, endTime },
    resolution: metricHelper.getResolution(startTime, endTime),
    ids: [id],
  };

  const requests = [];
  for (let lastTime = params.interval.startTime; lastTime < params.interval.endTime; lastTime += 2 * 60 * 60) {
    requests.push({
      ...params,
      interval: {
        startTime: lastTime,
        endTime: Math.min(lastTime + 2 * 60 * 60, params.interval.endTime),
      },
    });
  }

  const responses = await Promise.all(requests.map((r) => subscriberService.getMetrics(r)));

  const data = responses.flatMap(response => response[0]?.metricData[0]?.dataPointsEx || []);

  data.sort((a, b) => a?.timestamp - b?.timestamp);

  const lastPointTimestamp = moment(data[data.length - 1]?.timestamp).unix();

  const DataObj = {
    data,
    interval: { startTime, endTime },
    fullSync: lastRefreshTime === 0,
  };

  lastRefreshTime = lastPointTimestamp ?? 0;
  prevDeviceID = id;
  prevInterval = diff;

  return DataObj;
});

export const fetchDevicesLatestMetrics = createAsyncThunk(`subscriber/getLatestMetrics`, async (_: void, thunk) => {
  const state = thunk.getState() as RootState;
  const devices = state.subscriber.data;
  const connectedDeviceIds = devices.filter((d) => d.runtimeInfo?.status === CONNECTED).map((d) => d.id) as string[];
  if (connectedDeviceIds.length === 0) return {};


  const params = {

    metrics: deviceMetricsListAll,

 

    interval: {
      startTime: Math.floor(moment().unix() / 60) * 60 - 60,
        endTime: Math.floor(moment().unix() / 60) * 60,
    },
  resolution: 60,
  ids: connectedDeviceIds,
  };

const metrics = await subscriberService.getMetrics(params);
const deviceMetrics = {} as { [key: string]: DeviceMetrics };
connectedDeviceIds.forEach((id) => {
  deviceMetrics[id] = {
    timestamp: params.interval.endTime,    
  };
});
for (let a = 0; a < metrics.length; a++) {
  const obj = metrics[a];
  const { metric, metricData } = obj;
  for (let i = 0; i < metricData.length; i++) {
    const metricD = metricData[i];
    deviceMetrics[metricD.id][metric] = metricD.dataPoints?.[metricD.dataPoints?.length - 1]?.[1];
  }
}
return deviceMetrics;
});

export const fetchDNN = createAsyncThunk("subscriber/fetchDNN", async () => {
  try {
    const dnn = await dnnService.getDnn();
    return dnn;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const subscriberSlice = createSlice({
  name: "subscriber",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setValues: (state, action: PayloadAction<Device | null>) => {
      state.current = action.payload;
    },
    setImportOpen: (state, action: PayloadAction<boolean>) => {
      state.importOpen = action.payload;
    },
    setDetailsOpen: (state, action: PayloadAction<boolean>) => {
      state.detailsOpen = action.payload;
    },

    setHiddenColumns: (state, action: PayloadAction<string[]>) => {
      state.hiddenColumns = action.payload;
    },
    setPreset: (state, action: PayloadAction<string>) => {
      state.preset = action.payload;
    },
    setTimelineGraphLoading: (state, action: PayloadAction<boolean>) => {
      state.timelineGraph.loading = action.payload;
    },
    toggleTimelineLogs: (state) => {
      state.timelineGraph.showLogs = !state.timelineGraph.showLogs;
    },

    togglePingmonLogs: (state) => {
      state.pingmon.showLogs = !state.pingmon.showLogs;
    },

    toggleTimelineEvents: (state) => {
      // major => error => all => major
      const eventing = state.timelineGraph.eventShow;
      if (eventing === "major") {
        state.timelineGraph.eventShow = "error";
      } else if (eventing === "error") {
        state.timelineGraph.eventShow = "all";
      } else if (eventing === "all") {
        state.timelineGraph.eventShow = "major";
      }
    },
  },
  extraReducers: (builder) => {
    builder
      //devices
      .addCase(fetchDevices.fulfilled, (state, action) => {
        const { devices: data, radios } = action.payload;
        const _devices = SyncDeviceWithRuntimeRadioAPs(data, radios);
        const devices = SyncWithMetrics(_devices, state.currentMetrics);
        state.data = devices ?? [];
        // state.data = SyncwithGroups(devices, state.deviceGroups, state.preset) ?? [];

        // sync the current subscriber with the new data
        if (state.current && !state.open) {
          const current = state.data.find((device) => device.id === state.current?.id);
          if (current) {
            state.current = current;
          }
        }
        state.loading = false;

        const grpedDevices = groupBy(data, "groupId") as { [key: string]: Device[] };
        // calculate the length of connected devices for each group
        const connectedLengths = Object.keys(grpedDevices).reduce((acc, key) => {
          acc[key] = grpedDevices[key].filter(
            (device) => device.runtimeInfo?.status === CONNECTED || device.runtimeInfo?.status === IDLE
          ).length;
          return acc;
        }, {} as Record<string, number>);

        state.connectedDeviceGroups = connectedLengths;

        const calcLen = calcLengths(grpedDevices);
        state.deviceGroupMappingID = calcLen;
        state.status = "devices_loaded";
      })
      .addCase(fetchDevices.rejected, (state, action) => {
        state.status = "error_fetching_devices";
      })

      // Network DNN
      .addCase(fetchDNN.fulfilled, (state, action) => {
        state.networksData = action.payload;
        state.loading = false;
        state.status = "dnn_loaded";
      })
      .addCase(fetchDNN.rejected, (state) => {
        state.status = "error_fetching_DNN";
      })

      // Timeline Graph Data
      .addCase(fetchTimelineGraph.pending, (state) => {
        state.timelineGraph.loading = true;
      })
      .addCase(fetchTimelineGraph.fulfilled, (state, action) => {
        // do something
        const { data, radioMap, events } = action.payload;
        const connection_state =
          data.find((obj) => obj.metric === "ue_connection_state")?.metricData[0].dataPoints ?? [];
        const radio_connection =
          data.find((obj) => obj.metric === "ue_radio_connection")?.metricData[0].dataPoints ?? [];
        const timeRadioMap = Object.fromEntries(radio_connection);
        const syncedMetrics = syncMetrics(connection_state, timeRadioMap);

        const timelineData = formatXrangeData(connection_state, timeRadioMap, radioMap);
        const timeEvents = formatEventData(syncedMetrics, radioMap);
        const timelineLogs = formatLogs(syncedMetrics, radioMap);

        state.timelineGraph.timestamps = connection_state.map((item) => [item[0] * 1000, 0]);
        state.timelineGraph.timelineData = timelineData;
        state.timelineGraph.timelineEvents = timeEvents;
        state.timelineGraph.logs = timelineLogs;
        state.timelineGraph.loading = false;
        state.loading = false;

        state.timelineGraph.deviceEvents = formatDeviceEventData(events);
        state.status = "timeline_graph_loaded";
      })
      .addCase(fetchTimelineGraph.rejected, (state) => {
        state.status = "error_fetching_timeline_graph";
      })

      // Throughput Graph Data
      .addCase(fetchDeviceMetrics.fulfilled, (state, action) => {
        const { data, interval, resolution } = action.payload;

        data.forEach((obj) => {
          const metricData = obj.metricData.map((metric) => {
            if (!metric.dataPoints.length) return [];

            try {
              return fillTimeSeriesData({
                data: metric.dataPoints,
                startTime: interval.startTime,
                endTime: interval.endTime,
                interval: resolution,
                name: obj.metric,
                fillType: "null",
                modifier: ["device_download_throughput", "device_upload_throughput"].includes(obj.metric)
                  ? (v) => v * 8
                  : (v) => v,
              });
            } catch (e) {
              console.error('Error in fillTimeSeries', e, obj.metric, metric.dataPoints);
              return metric.dataPoints.length ? metric.dataPoints : [];
            }
          });
          state.metrics[obj.metric] = metricData[0];
        });

        state.loading = false;
        state.status = "throughput_graph_loaded";
      })
      .addCase(fetchDeviceMetrics.rejected, (state) => {
        // remove the metrics from the state
        networkMetricsList.forEach((metric) => {
          delete state.metrics[metric];
        });
        state.status = `error_fetching_throughput_graph`;
      })

      .addCase(fetchConnectionQualityMetrics.fulfilled, (state, action) => {
        const { data, interval, resolution } = action.payload;
        data.forEach((obj) => {
          const metricData = obj.metricData.map((metric) => {
            if (!metric.dataPoints.length) return [];
            try {
              return fillTimeSeriesData({
                data: metric.dataPoints,
                startTime: interval.startTime,
                endTime: interval.endTime,
                interval: resolution,
                name: obj.metric,
                fillType: "null",
              });
            }
            catch (e) {
              console.error('Error in fillTimeSeries', e, obj.metric, metric.dataPoints);
              return metric.dataPoints.length ? metric.dataPoints : [];
            }
          });
          state.metrics[obj.metric] = metricData[0];
        });

        state.loading = false; 
        state.status = "connection_quality_graph_loaded";
      })

      .addCase(fetchConnectionQualityMetrics.rejected, (state, action) => {
        // remove the metrics from the state
        deviceMetricsList.forEach((metric) => {
          delete state.metrics[metric];
        });
        state.status = `error_fetching_connection_quality_graph`;
      })

      // Radio Connection Quality
      .addCase(fetchRadioConnectionsQuality.fulfilled, (state, action) => {
        const prev = JSON.stringify(state.radioConnectionsQuality);
        const raw = JSON.stringify(action.payload);
        if (!(raw === prev)) {
          const data = action.payload;
          const payloads = data.map((obj) => {
            return {
              ...obj,
              metricData: obj.metricData.map((metric) => {
                return {
                  ...metric,
                  dataPoints: fillTimeSeriesData({
                    name: obj.metric,
                    data: metric.dataPoints,
                    startTime: action.meta.arg.interval.startTime,
                    endTime: action.meta.arg.interval.endTime,
                    interval: action.meta.arg.resolution,
                  }),
                };
              }),
            };
          });
          state.radioConnectionsQuality = payloads;
        }
        state.loading = false;
      })
      .addCase(fetchRadioConnectionsQuality.rejected, (state) => {
        state.radioConnectionsQuality = [];
        state.status = `error_fetching_radio_quality_graph`;
      })
      .addCase(fetchDevicesLatestMetrics.fulfilled, (state, action) => {
        const data = action.payload;
        state.currentMetrics = data;
        // update the devices with the latest metrics
        const devices = state.data;
        const _devices = SyncWithMetrics(devices, data);
        state.data = _devices;

        // sync the current subscriber with the new data
        if (state.current && !state.open) {
          const current = state.data.find((device) => device.id === state.current?.id);
          if (current) {
            state.current = current;
          }
        }

        state.loading = false;
      })
      .addCase(fetchPingmon.fulfilled, (state, action) => {
        const { data, interval, fullSync } = action.payload;

        if (fullSync) {
          // console.log("Full Sync");
          state.pingmon.pingmonMetrics = {
            ...transformPingMonData({ data, startTime: interval.startTime, endTime: interval.endTime }),
            raw: data,
          };
        } else {
          // remove the start data length and add new data to the data
          const dataLength = state.pingmon.pingmonMetrics.raw.findIndex((item) => {
            const timestamp = moment(item.timestamp).unix();
            return timestamp > interval.startTime;
          });
          const oldData = state.pingmon.pingmonMetrics.raw.slice(dataLength);
          const _data = [...oldData, ...data];
          // console.log("Partial Sync");
          state.pingmon.pingmonMetrics = {
            ...transformPingMonData({ data: _data, startTime: interval.startTime, endTime: interval.endTime }),
            raw: _data,
          };
        }
        state.loading = false;
      })
      .addCase(fetchPingmon.rejected, (state) => {
        state.status = `error_fetching_pingmon`;
      });
  },
});

export const subscriberActions = subscriberSlice.actions;
export default subscriberSlice.reducer;
export const subscriberOpen = (state: RootState) => state.subscriber.open;
export const subscriberHiddenColumns = (state: RootState) => state.subscriber.hiddenColumns;
export const subscriberPreset = (state: RootState) => state.subscriber.preset;

export const subscriberImportOpen = (state: RootState) => state.subscriber.importOpen;
export const subscriberDetailsOpen = (state: RootState) => state.subscriber.detailsOpen;

export const subscriberNetworksData = (state: RootState) => state.subscriber.networksData;

export const subscriberData = (state: RootState) => state.subscriber.data;
export const subscriberSelected = (state: RootState) => state.subscriber.current;
export const subscriberTimelineGraphLoading = (state: RootState) => state.subscriber.timelineGraph.loading;

export const subscriberTimelineData = (state: RootState) => state.subscriber.timelineGraph.timelineData;
export const subscriberTimelineEvents = (state: RootState) => state.subscriber.timelineGraph.timelineEvents;
export const subscriberTimelineLogs = (state: RootState) => state.subscriber.timelineGraph.logs;

export const subscriberRadioConnectionQuality = (state: RootState) => state.subscriber.radioConnectionsQuality;
export const subscriberMetrics = (state: RootState) => state.subscriber.metrics;
export const subscriberMetric = (metric: string) => (state: RootState) => state.subscriber.metrics[metric];
export const pingmonMetrics = (state: RootState) => state.subscriber.pingmon.pingmonMetrics;

export const subscriberDeviceGroupsMapping = (state: RootState) => state.subscriber.deviceGroupMappingID;
export const subscriberConnectedDeviceGroups = (state: RootState) => state.subscriber.connectedDeviceGroups;

export const subscriberMetricsError = (state: RootState) => state.subscriber.status.startsWith("error");

function SyncDeviceWithRuntimeRadioAPs(data: Device[], radioAPs: Radio[]) {
  const devices = data.map((row, i) => {
    const _device = row;

    // find the radio ap name from the radio aps list
    let radioApName = radioAPs?.find((radioAp) => radioAp.id === _device?.runtimeInfo?.radioId)?.name;
    if (!radioApName && _device?.runtimeInfo?.radioApId) {
      // console.log("Finding radio from cell parameters");
      radioApName = radioAPs?.find((radioAp) =>
        radioAp?.cellParameters?.find((cell) => cell?.cellId === _device?.runtimeInfo?.radioApId)
      )?.name;
    }
    // if radio ap name is not found, set eci as the radio ap name
    if (!radioApName && _device?.runtimeInfo?.radioId) {
      radioApName = _device?.runtimeInfo?.eci?.toString();
    }
    if (_device.runtimeInfo && radioApName) {
      // console.log("Found radio from data : " + radioApName);
      _device.runtimeInfo.radioApName = radioApName;
      _device.runtimeInfo.radioID = _device?.runtimeInfo?.radioId || _device?.runtimeInfo?.radioApId;
    }
    return _device;
  });
  return devices;
}

function SyncWithMetrics(
  devices: Device[],
  metricsObject: {
    [key: string]: DeviceMetrics;
  }
) {
  return devices.map((device) => {
    const deviceMetrics = metricsObject[device.id!];
    if (deviceMetrics) {
      device.metrics = deviceMetrics;
    }
    return device;
  });
}
