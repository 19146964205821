import "../../assets/index2.css";
import { jsx as l, jsxs as S, Fragment as I } from "react/jsx-runtime";
import { createContext as z, forwardRef as Mt, useContext as Z, useState as E, useEffect as U, useLayoutEffect as Dt, useRef as _t, useMemo as kt } from "react";
import Nt from "../../assets/icons/CalenderIcon.js";
import L from "moment-timezone";
import ge from "../Select/index.js";
import { getIntervalTime as Ct } from "../../utilities/datetime.js";
import { Divider as xt, Menu as Qe } from "@mui/material";
import Pt from "../../assets/icons/SeekIcon.js";
const Ot = "_container_1cp4n_1", St = "_nohover_1cp4n_17", Wt = "_range_1cp4n_24", Tt = "_date_1cp4n_33", Yt = "_time_1cp4n_33", Ft = "_selector_1cp4n_40", Lt = "_seek_1cp4n_44", A = {
  container: Ot,
  nohover: St,
  range: Wt,
  date: Tt,
  time: Yt,
  selector: Ft,
  seek: Lt
};
function D(e) {
  const t = Object.prototype.toString.call(e);
  return e instanceof Date || typeof e == "object" && t === "[object Date]" ? new e.constructor(+e) : typeof e == "number" || t === "[object Number]" || typeof e == "string" || t === "[object String]" ? new Date(e) : /* @__PURE__ */ new Date(NaN);
}
function R(e, t) {
  return e instanceof Date ? new e.constructor(t) : new Date(t);
}
function T(e, t) {
  const n = D(e);
  return isNaN(t) ? R(e, NaN) : (t && n.setDate(n.getDate() + t), n);
}
function B(e, t) {
  const n = D(e);
  if (isNaN(t))
    return R(e, NaN);
  if (!t)
    return n;
  const r = n.getDate(), a = R(e, n.getTime());
  a.setMonth(n.getMonth() + t + 1, 0);
  const o = a.getDate();
  return r >= o ? a : (n.setFullYear(
    a.getFullYear(),
    a.getMonth(),
    r
  ), n);
}
const Ye = 6048e5, Et = 864e5;
let It = {};
function le() {
  return It;
}
function $(e, t) {
  var s, u, d, c;
  const n = le(), r = (t == null ? void 0 : t.weekStartsOn) ?? ((u = (s = t == null ? void 0 : t.locale) == null ? void 0 : s.options) == null ? void 0 : u.weekStartsOn) ?? n.weekStartsOn ?? ((c = (d = n.locale) == null ? void 0 : d.options) == null ? void 0 : c.weekStartsOn) ?? 0, a = D(e), o = a.getDay(), i = (o < r ? 7 : 0) + o - r;
  return a.setDate(a.getDate() - i), a.setHours(0, 0, 0, 0), a;
}
function K(e) {
  return $(e, { weekStartsOn: 1 });
}
function nt(e) {
  const t = D(e), n = t.getFullYear(), r = R(e, 0);
  r.setFullYear(n + 1, 0, 4), r.setHours(0, 0, 0, 0);
  const a = K(r), o = R(e, 0);
  o.setFullYear(n, 0, 4), o.setHours(0, 0, 0, 0);
  const i = K(o);
  return t.getTime() >= a.getTime() ? n + 1 : t.getTime() >= i.getTime() ? n : n - 1;
}
function ae(e) {
  const t = D(e);
  return t.setHours(0, 0, 0, 0), t;
}
function we(e) {
  const t = D(e), n = new Date(
    Date.UTC(
      t.getFullYear(),
      t.getMonth(),
      t.getDate(),
      t.getHours(),
      t.getMinutes(),
      t.getSeconds(),
      t.getMilliseconds()
    )
  );
  return n.setUTCFullYear(t.getFullYear()), +e - +n;
}
function q(e, t) {
  const n = ae(e), r = ae(t), a = +n - we(n), o = +r - we(r);
  return Math.round((a - o) / Et);
}
function Rt(e) {
  const t = nt(e), n = R(e, 0);
  return n.setFullYear(t, 0, 4), n.setHours(0, 0, 0, 0), K(n);
}
function We(e, t) {
  const n = t * 7;
  return T(e, n);
}
function Bt(e, t) {
  return B(e, t * 12);
}
function Ht(e) {
  let t;
  return e.forEach(function(n) {
    const r = D(n);
    (t === void 0 || t < r || isNaN(Number(r))) && (t = r);
  }), t || /* @__PURE__ */ new Date(NaN);
}
function jt(e) {
  let t;
  return e.forEach((n) => {
    const r = D(n);
    (!t || t > r || isNaN(+r)) && (t = r);
  }), t || /* @__PURE__ */ new Date(NaN);
}
function Y(e, t) {
  const n = ae(e), r = ae(t);
  return +n == +r;
}
function Fe(e) {
  return e instanceof Date || typeof e == "object" && Object.prototype.toString.call(e) === "[object Date]";
}
function At(e) {
  if (!Fe(e) && typeof e != "number")
    return !1;
  const t = D(e);
  return !isNaN(Number(t));
}
function ue(e, t) {
  const n = D(e), r = D(t), a = n.getFullYear() - r.getFullYear(), o = n.getMonth() - r.getMonth();
  return a * 12 + o;
}
function qt(e, t, n) {
  const r = $(e, n), a = $(t, n), o = +r - we(r), i = +a - we(a);
  return Math.round((o - i) / Ye);
}
function Le(e) {
  const t = D(e), n = t.getMonth();
  return t.setFullYear(t.getFullYear(), n + 1, 0), t.setHours(23, 59, 59, 999), t;
}
function F(e) {
  const t = D(e);
  return t.setDate(1), t.setHours(0, 0, 0, 0), t;
}
function rt(e) {
  const t = D(e), n = R(e, 0);
  return n.setFullYear(t.getFullYear(), 0, 1), n.setHours(0, 0, 0, 0), n;
}
function Ee(e, t) {
  var s, u, d, c;
  const n = le(), r = (t == null ? void 0 : t.weekStartsOn) ?? ((u = (s = t == null ? void 0 : t.locale) == null ? void 0 : s.options) == null ? void 0 : u.weekStartsOn) ?? n.weekStartsOn ?? ((c = (d = n.locale) == null ? void 0 : d.options) == null ? void 0 : c.weekStartsOn) ?? 0, a = D(e), o = a.getDay(), i = (o < r ? -7 : 0) + 6 - (o - r);
  return a.setDate(a.getDate() + i), a.setHours(23, 59, 59, 999), a;
}
function at(e) {
  return Ee(e, { weekStartsOn: 1 });
}
const $t = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
}, Qt = (e, t, n) => {
  let r;
  const a = $t[e];
  return typeof a == "string" ? r = a : t === 1 ? r = a.one : r = a.other.replace("{{count}}", t.toString()), n != null && n.addSuffix ? n.comparison && n.comparison > 0 ? "in " + r : r + " ago" : r;
};
function ke(e) {
  return (t = {}) => {
    const n = t.width ? String(t.width) : e.defaultWidth;
    return e.formats[n] || e.formats[e.defaultWidth];
  };
}
const Xt = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
}, Gt = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
}, Vt = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, Ut = {
  date: ke({
    formats: Xt,
    defaultWidth: "full"
  }),
  time: ke({
    formats: Gt,
    defaultWidth: "full"
  }),
  dateTime: ke({
    formats: Vt,
    defaultWidth: "full"
  })
}, Jt = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
}, Kt = (e, t, n, r) => Jt[e];
function ie(e) {
  return (t, n) => {
    const r = n != null && n.context ? String(n.context) : "standalone";
    let a;
    if (r === "formatting" && e.formattingValues) {
      const i = e.defaultFormattingWidth || e.defaultWidth, s = n != null && n.width ? String(n.width) : i;
      a = e.formattingValues[s] || e.formattingValues[i];
    } else {
      const i = e.defaultWidth, s = n != null && n.width ? String(n.width) : e.defaultWidth;
      a = e.values[s] || e.values[i];
    }
    const o = e.argumentCallback ? e.argumentCallback(t) : t;
    return a[o];
  };
}
const zt = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
}, Zt = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
}, en = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  wide: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
}, tn = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
}, nn = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
}, rn = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
}, an = (e, t) => {
  const n = Number(e), r = n % 100;
  if (r > 20 || r < 10)
    switch (r % 10) {
      case 1:
        return n + "st";
      case 2:
        return n + "nd";
      case 3:
        return n + "rd";
    }
  return n + "th";
}, on = {
  ordinalNumber: an,
  era: ie({
    values: zt,
    defaultWidth: "wide"
  }),
  quarter: ie({
    values: Zt,
    defaultWidth: "wide",
    argumentCallback: (e) => e - 1
  }),
  month: ie({
    values: en,
    defaultWidth: "wide"
  }),
  day: ie({
    values: tn,
    defaultWidth: "wide"
  }),
  dayPeriod: ie({
    values: nn,
    defaultWidth: "wide",
    formattingValues: rn,
    defaultFormattingWidth: "wide"
  })
};
function se(e) {
  return (t, n = {}) => {
    const r = n.width, a = r && e.matchPatterns[r] || e.matchPatterns[e.defaultMatchWidth], o = t.match(a);
    if (!o)
      return null;
    const i = o[0], s = r && e.parsePatterns[r] || e.parsePatterns[e.defaultParseWidth], u = Array.isArray(s) ? un(s, (f) => f.test(i)) : (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I challange you to fix the type
      sn(s, (f) => f.test(i))
    );
    let d;
    d = e.valueCallback ? e.valueCallback(u) : u, d = n.valueCallback ? (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I challange you to fix the type
      n.valueCallback(d)
    ) : d;
    const c = t.slice(i.length);
    return { value: d, rest: c };
  };
}
function sn(e, t) {
  for (const n in e)
    if (Object.prototype.hasOwnProperty.call(e, n) && t(e[n]))
      return n;
}
function un(e, t) {
  for (let n = 0; n < e.length; n++)
    if (t(e[n]))
      return n;
}
function ln(e) {
  return (t, n = {}) => {
    const r = t.match(e.matchPattern);
    if (!r)
      return null;
    const a = r[0], o = t.match(e.parsePattern);
    if (!o)
      return null;
    let i = e.valueCallback ? e.valueCallback(o[0]) : o[0];
    i = n.valueCallback ? n.valueCallback(i) : i;
    const s = t.slice(a.length);
    return { value: i, rest: s };
  };
}
const cn = /^(\d+)(th|st|nd|rd)?/i, dn = /\d+/i, fn = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
}, hn = {
  any: [/^b/i, /^(a|c)/i]
}, mn = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
}, vn = {
  any: [/1/i, /2/i, /3/i, /4/i]
}, yn = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
}, gn = {
  narrow: [
    /^j/i,
    /^f/i,
    /^m/i,
    /^a/i,
    /^m/i,
    /^j/i,
    /^j/i,
    /^a/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ],
  any: [
    /^ja/i,
    /^f/i,
    /^mar/i,
    /^ap/i,
    /^may/i,
    /^jun/i,
    /^jul/i,
    /^au/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ]
}, bn = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
}, wn = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
}, pn = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
}, Mn = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
}, Dn = {
  ordinalNumber: ln({
    matchPattern: cn,
    parsePattern: dn,
    valueCallback: (e) => parseInt(e, 10)
  }),
  era: se({
    matchPatterns: fn,
    defaultMatchWidth: "wide",
    parsePatterns: hn,
    defaultParseWidth: "any"
  }),
  quarter: se({
    matchPatterns: mn,
    defaultMatchWidth: "wide",
    parsePatterns: vn,
    defaultParseWidth: "any",
    valueCallback: (e) => e + 1
  }),
  month: se({
    matchPatterns: yn,
    defaultMatchWidth: "wide",
    parsePatterns: gn,
    defaultParseWidth: "any"
  }),
  day: se({
    matchPatterns: bn,
    defaultMatchWidth: "wide",
    parsePatterns: wn,
    defaultParseWidth: "any"
  }),
  dayPeriod: se({
    matchPatterns: pn,
    defaultMatchWidth: "any",
    parsePatterns: Mn,
    defaultParseWidth: "any"
  })
}, ot = {
  code: "en-US",
  formatDistance: Qt,
  formatLong: Ut,
  formatRelative: Kt,
  localize: on,
  match: Dn,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
function _n(e) {
  const t = D(e);
  return q(t, rt(t)) + 1;
}
function it(e) {
  const t = D(e), n = +K(t) - +Rt(t);
  return Math.round(n / Ye) + 1;
}
function st(e, t) {
  var c, f, g, h;
  const n = D(e), r = n.getFullYear(), a = le(), o = (t == null ? void 0 : t.firstWeekContainsDate) ?? ((f = (c = t == null ? void 0 : t.locale) == null ? void 0 : c.options) == null ? void 0 : f.firstWeekContainsDate) ?? a.firstWeekContainsDate ?? ((h = (g = a.locale) == null ? void 0 : g.options) == null ? void 0 : h.firstWeekContainsDate) ?? 1, i = R(e, 0);
  i.setFullYear(r + 1, 0, o), i.setHours(0, 0, 0, 0);
  const s = $(i, t), u = R(e, 0);
  u.setFullYear(r, 0, o), u.setHours(0, 0, 0, 0);
  const d = $(u, t);
  return n.getTime() >= s.getTime() ? r + 1 : n.getTime() >= d.getTime() ? r : r - 1;
}
function kn(e, t) {
  var s, u, d, c;
  const n = le(), r = (t == null ? void 0 : t.firstWeekContainsDate) ?? ((u = (s = t == null ? void 0 : t.locale) == null ? void 0 : s.options) == null ? void 0 : u.firstWeekContainsDate) ?? n.firstWeekContainsDate ?? ((c = (d = n.locale) == null ? void 0 : d.options) == null ? void 0 : c.firstWeekContainsDate) ?? 1, a = st(e, t), o = R(e, 0);
  return o.setFullYear(a, 0, r), o.setHours(0, 0, 0, 0), $(o, t);
}
function ut(e, t) {
  const n = D(e), r = +$(n, t) - +kn(n, t);
  return Math.round(r / Ye) + 1;
}
function k(e, t) {
  const n = e < 0 ? "-" : "", r = Math.abs(e).toString().padStart(t, "0");
  return n + r;
}
const V = {
  // Year
  y(e, t) {
    const n = e.getFullYear(), r = n > 0 ? n : 1 - n;
    return k(t === "yy" ? r % 100 : r, t.length);
  },
  // Month
  M(e, t) {
    const n = e.getMonth();
    return t === "M" ? String(n + 1) : k(n + 1, 2);
  },
  // Day of the month
  d(e, t) {
    return k(e.getDate(), t.length);
  },
  // AM or PM
  a(e, t) {
    const n = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return n.toUpperCase();
      case "aaa":
        return n;
      case "aaaaa":
        return n[0];
      case "aaaa":
      default:
        return n === "am" ? "a.m." : "p.m.";
    }
  },
  // Hour [1-12]
  h(e, t) {
    return k(e.getHours() % 12 || 12, t.length);
  },
  // Hour [0-23]
  H(e, t) {
    return k(e.getHours(), t.length);
  },
  // Minute
  m(e, t) {
    return k(e.getMinutes(), t.length);
  },
  // Second
  s(e, t) {
    return k(e.getSeconds(), t.length);
  },
  // Fraction of second
  S(e, t) {
    const n = t.length, r = e.getMilliseconds(), a = Math.trunc(
      r * Math.pow(10, n - 3)
    );
    return k(a, t.length);
  }
}, re = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
}, Xe = {
  // Era
  G: function(e, t, n) {
    const r = e.getFullYear() > 0 ? 1 : 0;
    switch (t) {
      case "G":
      case "GG":
      case "GGG":
        return n.era(r, { width: "abbreviated" });
      case "GGGGG":
        return n.era(r, { width: "narrow" });
      case "GGGG":
      default:
        return n.era(r, { width: "wide" });
    }
  },
  // Year
  y: function(e, t, n) {
    if (t === "yo") {
      const r = e.getFullYear(), a = r > 0 ? r : 1 - r;
      return n.ordinalNumber(a, { unit: "year" });
    }
    return V.y(e, t);
  },
  // Local week-numbering year
  Y: function(e, t, n, r) {
    const a = st(e, r), o = a > 0 ? a : 1 - a;
    if (t === "YY") {
      const i = o % 100;
      return k(i, 2);
    }
    return t === "Yo" ? n.ordinalNumber(o, { unit: "year" }) : k(o, t.length);
  },
  // ISO week-numbering year
  R: function(e, t) {
    const n = nt(e);
    return k(n, t.length);
  },
  // Extended year. This is a single number designating the year of this calendar system.
  // The main difference between `y` and `u` localizers are B.C. years:
  // | Year | `y` | `u` |
  // |------|-----|-----|
  // | AC 1 |   1 |   1 |
  // | BC 1 |   1 |   0 |
  // | BC 2 |   2 |  -1 |
  // Also `yy` always returns the last two digits of a year,
  // while `uu` pads single digit years to 2 characters and returns other years unchanged.
  u: function(e, t) {
    const n = e.getFullYear();
    return k(n, t.length);
  },
  // Quarter
  Q: function(e, t, n) {
    const r = Math.ceil((e.getMonth() + 1) / 3);
    switch (t) {
      case "Q":
        return String(r);
      case "QQ":
        return k(r, 2);
      case "Qo":
        return n.ordinalNumber(r, { unit: "quarter" });
      case "QQQ":
        return n.quarter(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "QQQQQ":
        return n.quarter(r, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return n.quarter(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone quarter
  q: function(e, t, n) {
    const r = Math.ceil((e.getMonth() + 1) / 3);
    switch (t) {
      case "q":
        return String(r);
      case "qq":
        return k(r, 2);
      case "qo":
        return n.ordinalNumber(r, { unit: "quarter" });
      case "qqq":
        return n.quarter(r, {
          width: "abbreviated",
          context: "standalone"
        });
      case "qqqqq":
        return n.quarter(r, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return n.quarter(r, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Month
  M: function(e, t, n) {
    const r = e.getMonth();
    switch (t) {
      case "M":
      case "MM":
        return V.M(e, t);
      case "Mo":
        return n.ordinalNumber(r + 1, { unit: "month" });
      case "MMM":
        return n.month(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "MMMMM":
        return n.month(r, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return n.month(r, { width: "wide", context: "formatting" });
    }
  },
  // Stand-alone month
  L: function(e, t, n) {
    const r = e.getMonth();
    switch (t) {
      case "L":
        return String(r + 1);
      case "LL":
        return k(r + 1, 2);
      case "Lo":
        return n.ordinalNumber(r + 1, { unit: "month" });
      case "LLL":
        return n.month(r, {
          width: "abbreviated",
          context: "standalone"
        });
      case "LLLLL":
        return n.month(r, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return n.month(r, { width: "wide", context: "standalone" });
    }
  },
  // Local week of year
  w: function(e, t, n, r) {
    const a = ut(e, r);
    return t === "wo" ? n.ordinalNumber(a, { unit: "week" }) : k(a, t.length);
  },
  // ISO week of year
  I: function(e, t, n) {
    const r = it(e);
    return t === "Io" ? n.ordinalNumber(r, { unit: "week" }) : k(r, t.length);
  },
  // Day of the month
  d: function(e, t, n) {
    return t === "do" ? n.ordinalNumber(e.getDate(), { unit: "date" }) : V.d(e, t);
  },
  // Day of year
  D: function(e, t, n) {
    const r = _n(e);
    return t === "Do" ? n.ordinalNumber(r, { unit: "dayOfYear" }) : k(r, t.length);
  },
  // Day of week
  E: function(e, t, n) {
    const r = e.getDay();
    switch (t) {
      case "E":
      case "EE":
      case "EEE":
        return n.day(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "EEEEE":
        return n.day(r, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return n.day(r, {
          width: "short",
          context: "formatting"
        });
      case "EEEE":
      default:
        return n.day(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Local day of week
  e: function(e, t, n, r) {
    const a = e.getDay(), o = (a - r.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "e":
        return String(o);
      case "ee":
        return k(o, 2);
      case "eo":
        return n.ordinalNumber(o, { unit: "day" });
      case "eee":
        return n.day(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "eeeee":
        return n.day(a, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return n.day(a, {
          width: "short",
          context: "formatting"
        });
      case "eeee":
      default:
        return n.day(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone local day of week
  c: function(e, t, n, r) {
    const a = e.getDay(), o = (a - r.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "c":
        return String(o);
      case "cc":
        return k(o, t.length);
      case "co":
        return n.ordinalNumber(o, { unit: "day" });
      case "ccc":
        return n.day(a, {
          width: "abbreviated",
          context: "standalone"
        });
      case "ccccc":
        return n.day(a, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return n.day(a, {
          width: "short",
          context: "standalone"
        });
      case "cccc":
      default:
        return n.day(a, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // ISO day of week
  i: function(e, t, n) {
    const r = e.getDay(), a = r === 0 ? 7 : r;
    switch (t) {
      case "i":
        return String(a);
      case "ii":
        return k(a, t.length);
      case "io":
        return n.ordinalNumber(a, { unit: "day" });
      case "iii":
        return n.day(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "iiiii":
        return n.day(r, {
          width: "narrow",
          context: "formatting"
        });
      case "iiiiii":
        return n.day(r, {
          width: "short",
          context: "formatting"
        });
      case "iiii":
      default:
        return n.day(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM or PM
  a: function(e, t, n) {
    const a = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return n.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return n.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM, PM, midnight, noon
  b: function(e, t, n) {
    const r = e.getHours();
    let a;
    switch (r === 12 ? a = re.noon : r === 0 ? a = re.midnight : a = r / 12 >= 1 ? "pm" : "am", t) {
      case "b":
      case "bb":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return n.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return n.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // in the morning, in the afternoon, in the evening, at night
  B: function(e, t, n) {
    const r = e.getHours();
    let a;
    switch (r >= 17 ? a = re.evening : r >= 12 ? a = re.afternoon : r >= 4 ? a = re.morning : a = re.night, t) {
      case "B":
      case "BB":
      case "BBB":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return n.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return n.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Hour [1-12]
  h: function(e, t, n) {
    if (t === "ho") {
      let r = e.getHours() % 12;
      return r === 0 && (r = 12), n.ordinalNumber(r, { unit: "hour" });
    }
    return V.h(e, t);
  },
  // Hour [0-23]
  H: function(e, t, n) {
    return t === "Ho" ? n.ordinalNumber(e.getHours(), { unit: "hour" }) : V.H(e, t);
  },
  // Hour [0-11]
  K: function(e, t, n) {
    const r = e.getHours() % 12;
    return t === "Ko" ? n.ordinalNumber(r, { unit: "hour" }) : k(r, t.length);
  },
  // Hour [1-24]
  k: function(e, t, n) {
    let r = e.getHours();
    return r === 0 && (r = 24), t === "ko" ? n.ordinalNumber(r, { unit: "hour" }) : k(r, t.length);
  },
  // Minute
  m: function(e, t, n) {
    return t === "mo" ? n.ordinalNumber(e.getMinutes(), { unit: "minute" }) : V.m(e, t);
  },
  // Second
  s: function(e, t, n) {
    return t === "so" ? n.ordinalNumber(e.getSeconds(), { unit: "second" }) : V.s(e, t);
  },
  // Fraction of second
  S: function(e, t) {
    return V.S(e, t);
  },
  // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
  X: function(e, t, n) {
    const r = e.getTimezoneOffset();
    if (r === 0)
      return "Z";
    switch (t) {
      case "X":
        return Ve(r);
      case "XXXX":
      case "XX":
        return J(r);
      case "XXXXX":
      case "XXX":
      default:
        return J(r, ":");
    }
  },
  // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
  x: function(e, t, n) {
    const r = e.getTimezoneOffset();
    switch (t) {
      case "x":
        return Ve(r);
      case "xxxx":
      case "xx":
        return J(r);
      case "xxxxx":
      case "xxx":
      default:
        return J(r, ":");
    }
  },
  // Timezone (GMT)
  O: function(e, t, n) {
    const r = e.getTimezoneOffset();
    switch (t) {
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + Ge(r, ":");
      case "OOOO":
      default:
        return "GMT" + J(r, ":");
    }
  },
  // Timezone (specific non-location)
  z: function(e, t, n) {
    const r = e.getTimezoneOffset();
    switch (t) {
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + Ge(r, ":");
      case "zzzz":
      default:
        return "GMT" + J(r, ":");
    }
  },
  // Seconds timestamp
  t: function(e, t, n) {
    const r = Math.trunc(e.getTime() / 1e3);
    return k(r, t.length);
  },
  // Milliseconds timestamp
  T: function(e, t, n) {
    const r = e.getTime();
    return k(r, t.length);
  }
};
function Ge(e, t = "") {
  const n = e > 0 ? "-" : "+", r = Math.abs(e), a = Math.trunc(r / 60), o = r % 60;
  return o === 0 ? n + String(a) : n + String(a) + t + k(o, 2);
}
function Ve(e, t) {
  return e % 60 === 0 ? (e > 0 ? "-" : "+") + k(Math.abs(e) / 60, 2) : J(e, t);
}
function J(e, t = "") {
  const n = e > 0 ? "-" : "+", r = Math.abs(e), a = k(Math.trunc(r / 60), 2), o = k(r % 60, 2);
  return n + a + t + o;
}
const Ue = (e, t) => {
  switch (e) {
    case "P":
      return t.date({ width: "short" });
    case "PP":
      return t.date({ width: "medium" });
    case "PPP":
      return t.date({ width: "long" });
    case "PPPP":
    default:
      return t.date({ width: "full" });
  }
}, lt = (e, t) => {
  switch (e) {
    case "p":
      return t.time({ width: "short" });
    case "pp":
      return t.time({ width: "medium" });
    case "ppp":
      return t.time({ width: "long" });
    case "pppp":
    default:
      return t.time({ width: "full" });
  }
}, Nn = (e, t) => {
  const n = e.match(/(P+)(p+)?/) || [], r = n[1], a = n[2];
  if (!a)
    return Ue(e, t);
  let o;
  switch (r) {
    case "P":
      o = t.dateTime({ width: "short" });
      break;
    case "PP":
      o = t.dateTime({ width: "medium" });
      break;
    case "PPP":
      o = t.dateTime({ width: "long" });
      break;
    case "PPPP":
    default:
      o = t.dateTime({ width: "full" });
      break;
  }
  return o.replace("{{date}}", Ue(r, t)).replace("{{time}}", lt(a, t));
}, Cn = {
  p: lt,
  P: Nn
}, xn = /^D+$/, Pn = /^Y+$/, On = ["D", "DD", "YY", "YYYY"];
function Sn(e) {
  return xn.test(e);
}
function Wn(e) {
  return Pn.test(e);
}
function Tn(e, t, n) {
  const r = Yn(e, t, n);
  if (console.warn(r), On.includes(e))
    throw new RangeError(r);
}
function Yn(e, t, n) {
  const r = e[0] === "Y" ? "years" : "days of the month";
  return `Use \`${e.toLowerCase()}\` instead of \`${e}\` (in \`${t}\`) for formatting ${r} to the input \`${n}\`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md`;
}
const Fn = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, Ln = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, En = /^'([^]*?)'?$/, In = /''/g, Rn = /[a-zA-Z]/;
function ee(e, t, n) {
  var c, f, g, h, y, M, b, _;
  const r = le(), a = (n == null ? void 0 : n.locale) ?? r.locale ?? ot, o = (n == null ? void 0 : n.firstWeekContainsDate) ?? ((f = (c = n == null ? void 0 : n.locale) == null ? void 0 : c.options) == null ? void 0 : f.firstWeekContainsDate) ?? r.firstWeekContainsDate ?? ((h = (g = r.locale) == null ? void 0 : g.options) == null ? void 0 : h.firstWeekContainsDate) ?? 1, i = (n == null ? void 0 : n.weekStartsOn) ?? ((M = (y = n == null ? void 0 : n.locale) == null ? void 0 : y.options) == null ? void 0 : M.weekStartsOn) ?? r.weekStartsOn ?? ((_ = (b = r.locale) == null ? void 0 : b.options) == null ? void 0 : _.weekStartsOn) ?? 0, s = D(e);
  if (!At(s))
    throw new RangeError("Invalid time value");
  let u = t.match(Ln).map((p) => {
    const C = p[0];
    if (C === "p" || C === "P") {
      const j = Cn[C];
      return j(p, a.formatLong);
    }
    return p;
  }).join("").match(Fn).map((p) => {
    if (p === "''")
      return { isToken: !1, value: "'" };
    const C = p[0];
    if (C === "'")
      return { isToken: !1, value: Bn(p) };
    if (Xe[C])
      return { isToken: !0, value: p };
    if (C.match(Rn))
      throw new RangeError(
        "Format string contains an unescaped latin alphabet character `" + C + "`"
      );
    return { isToken: !1, value: p };
  });
  a.localize.preprocessor && (u = a.localize.preprocessor(s, u));
  const d = {
    firstWeekContainsDate: o,
    weekStartsOn: i,
    locale: a
  };
  return u.map((p) => {
    if (!p.isToken)
      return p.value;
    const C = p.value;
    (!(n != null && n.useAdditionalWeekYearTokens) && Wn(C) || !(n != null && n.useAdditionalDayOfYearTokens) && Sn(C)) && Tn(C, t, String(e));
    const j = Xe[C[0]];
    return j(s, C, a.localize, d);
  }).join("");
}
function Bn(e) {
  const t = e.match(En);
  return t ? t[1].replace(In, "'") : e;
}
function Hn(e) {
  const t = D(e), n = t.getFullYear(), r = t.getMonth(), a = R(e, 0);
  return a.setFullYear(n, r + 1, 0), a.setHours(0, 0, 0, 0), a.getDate();
}
function jn(e) {
  return Math.trunc(+D(e) / 1e3);
}
function An(e) {
  const t = D(e), n = t.getMonth();
  return t.setFullYear(t.getFullYear(), n + 1, 0), t.setHours(0, 0, 0, 0), t;
}
function qn(e, t) {
  return qt(
    An(e),
    F(e),
    t
  ) + 1;
}
function Te(e, t) {
  const n = D(e), r = D(t);
  return n.getTime() > r.getTime();
}
function ct(e, t) {
  const n = D(e), r = D(t);
  return +n < +r;
}
function Ie(e, t) {
  const n = D(e), r = D(t);
  return n.getFullYear() === r.getFullYear() && n.getMonth() === r.getMonth();
}
function $n(e, t) {
  const n = D(e), r = D(t);
  return n.getFullYear() === r.getFullYear();
}
function Ne(e, t) {
  return T(e, -t);
}
function Ce(e, t) {
  const n = D(e), r = n.getFullYear(), a = n.getDate(), o = R(e, 0);
  o.setFullYear(r, t, 15), o.setHours(0, 0, 0, 0);
  const i = Hn(o);
  return n.setMonth(t, Math.min(a, i)), n;
}
function Je(e, t) {
  const n = D(e);
  return isNaN(+n) ? R(e, NaN) : (n.setFullYear(t), n);
}
var w = function() {
  return w = Object.assign || function(t) {
    for (var n, r = 1, a = arguments.length; r < a; r++) {
      n = arguments[r];
      for (var o in n)
        Object.prototype.hasOwnProperty.call(n, o) && (t[o] = n[o]);
    }
    return t;
  }, w.apply(this, arguments);
};
function Qn(e, t) {
  var n = {};
  for (var r in e)
    Object.prototype.hasOwnProperty.call(e, r) && t.indexOf(r) < 0 && (n[r] = e[r]);
  if (e != null && typeof Object.getOwnPropertySymbols == "function")
    for (var a = 0, r = Object.getOwnPropertySymbols(e); a < r.length; a++)
      t.indexOf(r[a]) < 0 && Object.prototype.propertyIsEnumerable.call(e, r[a]) && (n[r[a]] = e[r[a]]);
  return n;
}
function dt(e, t, n) {
  if (n || arguments.length === 2)
    for (var r = 0, a = t.length, o; r < a; r++)
      (o || !(r in t)) && (o || (o = Array.prototype.slice.call(t, 0, r)), o[r] = t[r]);
  return e.concat(o || Array.prototype.slice.call(t));
}
function ce(e) {
  return e.mode === "multiple";
}
function de(e) {
  return e.mode === "range";
}
function Me(e) {
  return e.mode === "single";
}
var Xn = {
  root: "rdp",
  multiple_months: "rdp-multiple_months",
  with_weeknumber: "rdp-with_weeknumber",
  vhidden: "rdp-vhidden",
  button_reset: "rdp-button_reset",
  button: "rdp-button",
  caption: "rdp-caption",
  caption_start: "rdp-caption_start",
  caption_end: "rdp-caption_end",
  caption_between: "rdp-caption_between",
  caption_label: "rdp-caption_label",
  caption_dropdowns: "rdp-caption_dropdowns",
  dropdown: "rdp-dropdown",
  dropdown_month: "rdp-dropdown_month",
  dropdown_year: "rdp-dropdown_year",
  dropdown_icon: "rdp-dropdown_icon",
  months: "rdp-months",
  month: "rdp-month",
  table: "rdp-table",
  tbody: "rdp-tbody",
  tfoot: "rdp-tfoot",
  head: "rdp-head",
  head_row: "rdp-head_row",
  head_cell: "rdp-head_cell",
  nav: "rdp-nav",
  nav_button: "rdp-nav_button",
  nav_button_previous: "rdp-nav_button_previous",
  nav_button_next: "rdp-nav_button_next",
  nav_icon: "rdp-nav_icon",
  row: "rdp-row",
  weeknumber: "rdp-weeknumber",
  cell: "rdp-cell",
  day: "rdp-day",
  day_today: "rdp-day_today",
  day_outside: "rdp-day_outside",
  day_selected: "rdp-day_selected",
  day_disabled: "rdp-day_disabled",
  day_hidden: "rdp-day_hidden",
  day_range_start: "rdp-day_range_start",
  day_range_end: "rdp-day_range_end",
  day_range_middle: "rdp-day_range_middle"
};
function Gn(e, t) {
  return ee(e, "LLLL y", t);
}
function Vn(e, t) {
  return ee(e, "d", t);
}
function Un(e, t) {
  return ee(e, "LLLL", t);
}
function Jn(e) {
  return "".concat(e);
}
function Kn(e, t) {
  return ee(e, "cccccc", t);
}
function zn(e, t) {
  return ee(e, "yyyy", t);
}
var Zn = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  formatCaption: Gn,
  formatDay: Vn,
  formatMonthCaption: Un,
  formatWeekNumber: Jn,
  formatWeekdayName: Kn,
  formatYearCaption: zn
}), er = function(e, t, n) {
  return ee(e, "do MMMM (EEEE)", n);
}, tr = function() {
  return "Month: ";
}, nr = function() {
  return "Go to next month";
}, rr = function() {
  return "Go to previous month";
}, ar = function(e, t) {
  return ee(e, "cccc", t);
}, or = function(e) {
  return "Week n. ".concat(e);
}, ir = function() {
  return "Year: ";
}, sr = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  labelDay: er,
  labelMonthDropdown: tr,
  labelNext: nr,
  labelPrevious: rr,
  labelWeekNumber: or,
  labelWeekday: ar,
  labelYearDropdown: ir
});
function ur() {
  var e = "buttons", t = Xn, n = ot, r = {}, a = {}, o = 1, i = {}, s = /* @__PURE__ */ new Date();
  return {
    captionLayout: e,
    classNames: t,
    formatters: Zn,
    labels: sr,
    locale: n,
    modifiersClassNames: r,
    modifiers: a,
    numberOfMonths: o,
    styles: i,
    today: s,
    mode: "default"
  };
}
function lr(e) {
  var t = e.fromYear, n = e.toYear, r = e.fromMonth, a = e.toMonth, o = e.fromDate, i = e.toDate;
  return r ? o = F(r) : t && (o = new Date(t, 0, 1)), a ? i = Le(a) : n && (i = new Date(n, 11, 31)), {
    fromDate: o ? ae(o) : void 0,
    toDate: i ? ae(i) : void 0
  };
}
var ft = z(void 0);
function cr(e) {
  var t, n = e.initialProps, r = ur(), a = lr(n), o = a.fromDate, i = a.toDate, s = (t = n.captionLayout) !== null && t !== void 0 ? t : r.captionLayout;
  s !== "buttons" && (!o || !i) && (s = "buttons");
  var u;
  (Me(n) || ce(n) || de(n)) && (u = n.onSelect);
  var d = w(w(w({}, r), n), { captionLayout: s, classNames: w(w({}, r.classNames), n.classNames), components: w({}, n.components), formatters: w(w({}, r.formatters), n.formatters), fromDate: o, labels: w(w({}, r.labels), n.labels), mode: n.mode || r.mode, modifiers: w(w({}, r.modifiers), n.modifiers), modifiersClassNames: w(w({}, r.modifiersClassNames), n.modifiersClassNames), onSelect: u, styles: w(w({}, r.styles), n.styles), toDate: i });
  return l(ft.Provider, { value: d, children: e.children });
}
function N() {
  var e = Z(ft);
  if (!e)
    throw new Error("useDayPicker must be used within a DayPickerProvider.");
  return e;
}
function ht(e) {
  var t = N(), n = t.locale, r = t.classNames, a = t.styles, o = t.formatters.formatCaption;
  return l("div", { className: r.caption_label, style: a.caption_label, "aria-live": "polite", role: "presentation", id: e.id, children: o(e.displayMonth, { locale: n }) });
}
function dr(e) {
  return l("svg", w({ width: "8px", height: "8px", viewBox: "0 0 120 120", "data-testid": "iconDropdown" }, e, { children: l("path", { d: "M4.22182541,48.2218254 C8.44222828,44.0014225 15.2388494,43.9273804 19.5496459,47.9996989 L19.7781746,48.2218254 L60,88.443 L100.221825,48.2218254 C104.442228,44.0014225 111.238849,43.9273804 115.549646,47.9996989 L115.778175,48.2218254 C119.998577,52.4422283 120.07262,59.2388494 116.000301,63.5496459 L115.778175,63.7781746 L67.7781746,111.778175 C63.5577717,115.998577 56.7611506,116.07262 52.4503541,112.000301 L52.2218254,111.778175 L4.22182541,63.7781746 C-0.0739418023,59.4824074 -0.0739418023,52.5175926 4.22182541,48.2218254 Z", fill: "currentColor", fillRule: "nonzero" }) }));
}
function mt(e) {
  var t, n, r = e.onChange, a = e.value, o = e.children, i = e.caption, s = e.className, u = e.style, d = N(), c = (n = (t = d.components) === null || t === void 0 ? void 0 : t.IconDropdown) !== null && n !== void 0 ? n : dr;
  return S("div", { className: s, style: u, children: [l("span", { className: d.classNames.vhidden, children: e["aria-label"] }), l("select", { name: e.name, "aria-label": e["aria-label"], className: d.classNames.dropdown, style: d.styles.dropdown, value: a, onChange: r, children: o }), S("div", { className: d.classNames.caption_label, style: d.styles.caption_label, "aria-hidden": "true", children: [i, l(c, { className: d.classNames.dropdown_icon, style: d.styles.dropdown_icon })] })] });
}
function fr(e) {
  var t, n = N(), r = n.fromDate, a = n.toDate, o = n.styles, i = n.locale, s = n.formatters.formatMonthCaption, u = n.classNames, d = n.components, c = n.labels.labelMonthDropdown;
  if (!r)
    return l(I, {});
  if (!a)
    return l(I, {});
  var f = [];
  if ($n(r, a))
    for (var g = F(r), h = r.getMonth(); h <= a.getMonth(); h++)
      f.push(Ce(g, h));
  else
    for (var g = F(/* @__PURE__ */ new Date()), h = 0; h <= 11; h++)
      f.push(Ce(g, h));
  var y = function(b) {
    var _ = Number(b.target.value), p = Ce(F(e.displayMonth), _);
    e.onChange(p);
  }, M = (t = d == null ? void 0 : d.Dropdown) !== null && t !== void 0 ? t : mt;
  return l(M, { name: "months", "aria-label": c(), className: u.dropdown_month, style: o.dropdown_month, onChange: y, value: e.displayMonth.getMonth(), caption: s(e.displayMonth, { locale: i }), children: f.map(function(b) {
    return l("option", { value: b.getMonth(), children: s(b, { locale: i }) }, b.getMonth());
  }) });
}
function hr(e) {
  var t, n = e.displayMonth, r = N(), a = r.fromDate, o = r.toDate, i = r.locale, s = r.styles, u = r.classNames, d = r.components, c = r.formatters.formatYearCaption, f = r.labels.labelYearDropdown, g = [];
  if (!a)
    return l(I, {});
  if (!o)
    return l(I, {});
  for (var h = a.getFullYear(), y = o.getFullYear(), M = h; M <= y; M++)
    g.push(Je(rt(/* @__PURE__ */ new Date()), M));
  var b = function(p) {
    var C = Je(F(n), Number(p.target.value));
    e.onChange(C);
  }, _ = (t = d == null ? void 0 : d.Dropdown) !== null && t !== void 0 ? t : mt;
  return l(_, { name: "years", "aria-label": f(), className: u.dropdown_year, style: s.dropdown_year, onChange: b, value: n.getFullYear(), caption: c(n, { locale: i }), children: g.map(function(p) {
    return l("option", { value: p.getFullYear(), children: c(p, { locale: i }) }, p.getFullYear());
  }) });
}
function mr(e, t) {
  var n = E(e), r = n[0], a = n[1], o = t === void 0 ? r : t;
  return [o, a];
}
function vr(e) {
  var t = e.month, n = e.defaultMonth, r = e.today, a = t || n || r || /* @__PURE__ */ new Date(), o = e.toDate, i = e.fromDate, s = e.numberOfMonths, u = s === void 0 ? 1 : s;
  if (o && ue(o, a) < 0) {
    var d = -1 * (u - 1);
    a = B(o, d);
  }
  return i && ue(a, i) < 0 && (a = i), F(a);
}
function yr() {
  var e = N(), t = vr(e), n = mr(t, e.month), r = n[0], a = n[1], o = function(i) {
    var s;
    if (!e.disableNavigation) {
      var u = F(i);
      a(u), (s = e.onMonthChange) === null || s === void 0 || s.call(e, u);
    }
  };
  return [r, o];
}
function gr(e, t) {
  for (var n = t.reverseMonths, r = t.numberOfMonths, a = F(e), o = F(B(a, r)), i = ue(o, a), s = [], u = 0; u < i; u++) {
    var d = B(a, u);
    s.push(d);
  }
  return n && (s = s.reverse()), s;
}
function br(e, t) {
  if (!t.disableNavigation) {
    var n = t.toDate, r = t.pagedNavigation, a = t.numberOfMonths, o = a === void 0 ? 1 : a, i = r ? o : 1, s = F(e);
    if (!n)
      return B(s, i);
    var u = ue(n, e);
    if (!(u < o))
      return B(s, i);
  }
}
function wr(e, t) {
  if (!t.disableNavigation) {
    var n = t.fromDate, r = t.pagedNavigation, a = t.numberOfMonths, o = a === void 0 ? 1 : a, i = r ? o : 1, s = F(e);
    if (!n)
      return B(s, -i);
    var u = ue(s, n);
    if (!(u <= 0))
      return B(s, -i);
  }
}
var vt = z(void 0);
function pr(e) {
  var t = N(), n = yr(), r = n[0], a = n[1], o = gr(r, t), i = br(r, t), s = wr(r, t), u = function(f) {
    return o.some(function(g) {
      return Ie(f, g);
    });
  }, d = function(f, g) {
    u(f) || (g && ct(f, g) ? a(B(f, 1 + t.numberOfMonths * -1)) : a(f));
  }, c = {
    currentMonth: r,
    displayMonths: o,
    goToMonth: a,
    goToDate: d,
    previousMonth: s,
    nextMonth: i,
    isDateDisplayed: u
  };
  return l(vt.Provider, { value: c, children: e.children });
}
function fe() {
  var e = Z(vt);
  if (!e)
    throw new Error("useNavigation must be used within a NavigationProvider");
  return e;
}
function Ke(e) {
  var t, n = N(), r = n.classNames, a = n.styles, o = n.components, i = fe().goToMonth, s = function(c) {
    i(B(c, e.displayIndex ? -e.displayIndex : 0));
  }, u = (t = o == null ? void 0 : o.CaptionLabel) !== null && t !== void 0 ? t : ht, d = l(u, { id: e.id, displayMonth: e.displayMonth });
  return S("div", { className: r.caption_dropdowns, style: a.caption_dropdowns, children: [l("div", { className: r.vhidden, children: d }), l(fr, { onChange: s, displayMonth: e.displayMonth }), l(hr, { onChange: s, displayMonth: e.displayMonth })] });
}
function Mr(e) {
  return l("svg", w({ width: "16px", height: "16px", viewBox: "0 0 120 120" }, e, { children: l("path", { d: "M69.490332,3.34314575 C72.6145263,0.218951416 77.6798462,0.218951416 80.8040405,3.34314575 C83.8617626,6.40086786 83.9268205,11.3179931 80.9992143,14.4548388 L80.8040405,14.6568542 L35.461,60 L80.8040405,105.343146 C83.8617626,108.400868 83.9268205,113.317993 80.9992143,116.454839 L80.8040405,116.656854 C77.7463184,119.714576 72.8291931,119.779634 69.6923475,116.852028 L69.490332,116.656854 L18.490332,65.6568542 C15.4326099,62.5991321 15.367552,57.6820069 18.2951583,54.5451612 L18.490332,54.3431458 L69.490332,3.34314575 Z", fill: "currentColor", fillRule: "nonzero" }) }));
}
function Dr(e) {
  return l("svg", w({ width: "16px", height: "16px", viewBox: "0 0 120 120" }, e, { children: l("path", { d: "M49.8040405,3.34314575 C46.6798462,0.218951416 41.6145263,0.218951416 38.490332,3.34314575 C35.4326099,6.40086786 35.367552,11.3179931 38.2951583,14.4548388 L38.490332,14.6568542 L83.8333725,60 L38.490332,105.343146 C35.4326099,108.400868 35.367552,113.317993 38.2951583,116.454839 L38.490332,116.656854 C41.5480541,119.714576 46.4651794,119.779634 49.602025,116.852028 L49.8040405,116.656854 L100.804041,65.6568542 C103.861763,62.5991321 103.926821,57.6820069 100.999214,54.5451612 L100.804041,54.3431458 L49.8040405,3.34314575 Z", fill: "currentColor" }) }));
}
var pe = Mt(function(e, t) {
  var n = N(), r = n.classNames, a = n.styles, o = [r.button_reset, r.button];
  e.className && o.push(e.className);
  var i = o.join(" "), s = w(w({}, a.button_reset), a.button);
  return e.style && Object.assign(s, e.style), l("button", w({}, e, { ref: t, type: "button", className: i, style: s }));
});
function _r(e) {
  var t, n, r = N(), a = r.dir, o = r.locale, i = r.classNames, s = r.styles, u = r.labels, d = u.labelPrevious, c = u.labelNext, f = r.components;
  if (!e.nextMonth && !e.previousMonth)
    return l(I, {});
  var g = d(e.previousMonth, { locale: o }), h = [
    i.nav_button,
    i.nav_button_previous
  ].join(" "), y = c(e.nextMonth, { locale: o }), M = [
    i.nav_button,
    i.nav_button_next
  ].join(" "), b = (t = f == null ? void 0 : f.IconRight) !== null && t !== void 0 ? t : Dr, _ = (n = f == null ? void 0 : f.IconLeft) !== null && n !== void 0 ? n : Mr;
  return S("div", { className: i.nav, style: s.nav, children: [!e.hidePrevious && l(pe, { name: "previous-month", "aria-label": g, className: h, style: s.nav_button_previous, disabled: !e.previousMonth, onClick: e.onPreviousClick, children: a === "rtl" ? l(b, { className: i.nav_icon, style: s.nav_icon }) : l(_, { className: i.nav_icon, style: s.nav_icon }) }), !e.hideNext && l(pe, { name: "next-month", "aria-label": y, className: M, style: s.nav_button_next, disabled: !e.nextMonth, onClick: e.onNextClick, children: a === "rtl" ? l(_, { className: i.nav_icon, style: s.nav_icon }) : l(b, { className: i.nav_icon, style: s.nav_icon }) })] });
}
function ze(e) {
  var t = N().numberOfMonths, n = fe(), r = n.previousMonth, a = n.nextMonth, o = n.goToMonth, i = n.displayMonths, s = i.findIndex(function(y) {
    return Ie(e.displayMonth, y);
  }), u = s === 0, d = s === i.length - 1, c = t > 1 && (u || !d), f = t > 1 && (d || !u), g = function() {
    r && o(r);
  }, h = function() {
    a && o(a);
  };
  return l(_r, { displayMonth: e.displayMonth, hideNext: c, hidePrevious: f, nextMonth: a, previousMonth: r, onPreviousClick: g, onNextClick: h });
}
function kr(e) {
  var t, n = N(), r = n.classNames, a = n.disableNavigation, o = n.styles, i = n.captionLayout, s = n.components, u = (t = s == null ? void 0 : s.CaptionLabel) !== null && t !== void 0 ? t : ht, d;
  return a ? d = l(u, { id: e.id, displayMonth: e.displayMonth }) : i === "dropdown" ? d = l(Ke, { displayMonth: e.displayMonth, id: e.id }) : i === "dropdown-buttons" ? d = S(I, { children: [l(Ke, { displayMonth: e.displayMonth, displayIndex: e.displayIndex, id: e.id }), l(ze, { displayMonth: e.displayMonth, displayIndex: e.displayIndex, id: e.id })] }) : d = S(I, { children: [l(u, { id: e.id, displayMonth: e.displayMonth, displayIndex: e.displayIndex }), l(ze, { displayMonth: e.displayMonth, id: e.id })] }), l("div", { className: r.caption, style: o.caption, children: d });
}
function Nr(e) {
  var t = N(), n = t.footer, r = t.styles, a = t.classNames.tfoot;
  return n ? l("tfoot", { className: a, style: r.tfoot, children: l("tr", { children: l("td", { colSpan: 8, children: n }) }) }) : l(I, {});
}
function Cr(e, t, n) {
  for (var r = n ? K(/* @__PURE__ */ new Date()) : $(/* @__PURE__ */ new Date(), { locale: e, weekStartsOn: t }), a = [], o = 0; o < 7; o++) {
    var i = T(r, o);
    a.push(i);
  }
  return a;
}
function xr() {
  var e = N(), t = e.classNames, n = e.styles, r = e.showWeekNumber, a = e.locale, o = e.weekStartsOn, i = e.ISOWeek, s = e.formatters.formatWeekdayName, u = e.labels.labelWeekday, d = Cr(a, o, i);
  return S("tr", { style: n.head_row, className: t.head_row, children: [r && l("td", { style: n.head_cell, className: t.head_cell }), d.map(function(c, f) {
    return l("th", { scope: "col", className: t.head_cell, style: n.head_cell, "aria-label": u(c, { locale: a }), children: s(c, { locale: a }) }, f);
  })] });
}
function Pr() {
  var e, t = N(), n = t.classNames, r = t.styles, a = t.components, o = (e = a == null ? void 0 : a.HeadRow) !== null && e !== void 0 ? e : xr;
  return l("thead", { style: r.head, className: n.head, children: l(o, {}) });
}
function Or(e) {
  var t = N(), n = t.locale, r = t.formatters.formatDay;
  return l(I, { children: r(e.date, { locale: n }) });
}
var Re = z(void 0);
function Sr(e) {
  if (!ce(e.initialProps)) {
    var t = {
      selected: void 0,
      modifiers: {
        disabled: []
      }
    };
    return l(Re.Provider, { value: t, children: e.children });
  }
  return l(Wr, { initialProps: e.initialProps, children: e.children });
}
function Wr(e) {
  var t = e.initialProps, n = e.children, r = t.selected, a = t.min, o = t.max, i = function(d, c, f) {
    var g, h;
    (g = t.onDayClick) === null || g === void 0 || g.call(t, d, c, f);
    var y = !!(c.selected && a && (r == null ? void 0 : r.length) === a);
    if (!y) {
      var M = !!(!c.selected && o && (r == null ? void 0 : r.length) === o);
      if (!M) {
        var b = r ? dt([], r, !0) : [];
        if (c.selected) {
          var _ = b.findIndex(function(p) {
            return Y(d, p);
          });
          b.splice(_, 1);
        } else
          b.push(d);
        (h = t.onSelect) === null || h === void 0 || h.call(t, b, d, c, f);
      }
    }
  }, s = {
    disabled: []
  };
  r && s.disabled.push(function(d) {
    var c = o && r.length > o - 1, f = r.some(function(g) {
      return Y(g, d);
    });
    return !!(c && !f);
  });
  var u = {
    selected: r,
    onDayClick: i,
    modifiers: s
  };
  return l(Re.Provider, { value: u, children: n });
}
function Be() {
  var e = Z(Re);
  if (!e)
    throw new Error("useSelectMultiple must be used within a SelectMultipleProvider");
  return e;
}
function Tr(e, t) {
  var n = t || {}, r = n.from, a = n.to;
  return r && a ? Y(a, e) && Y(r, e) ? void 0 : Y(a, e) ? { from: a, to: void 0 } : Y(r, e) ? void 0 : Te(r, e) ? { from: e, to: a } : { from: r, to: e } : a ? Te(e, a) ? { from: a, to: e } : { from: e, to: a } : r ? ct(e, r) ? { from: e, to: r } : { from: r, to: e } : { from: e, to: void 0 };
}
var He = z(void 0);
function Yr(e) {
  if (!de(e.initialProps)) {
    var t = {
      selected: void 0,
      modifiers: {
        range_start: [],
        range_end: [],
        range_middle: [],
        disabled: []
      }
    };
    return l(He.Provider, { value: t, children: e.children });
  }
  return l(Fr, { initialProps: e.initialProps, children: e.children });
}
function Fr(e) {
  var t = e.initialProps, n = e.children, r = t.selected, a = r || {}, o = a.from, i = a.to, s = t.min, u = t.max, d = function(h, y, M) {
    var b, _;
    (b = t.onDayClick) === null || b === void 0 || b.call(t, h, y, M);
    var p = Tr(h, r);
    (_ = t.onSelect) === null || _ === void 0 || _.call(t, p, h, y, M);
  }, c = {
    range_start: [],
    range_end: [],
    range_middle: [],
    disabled: []
  };
  if (o ? (c.range_start = [o], i ? (c.range_end = [i], Y(o, i) || (c.range_middle = [
    {
      after: o,
      before: i
    }
  ])) : c.range_end = [o]) : i && (c.range_start = [i], c.range_end = [i]), s && (o && !i && c.disabled.push({
    after: Ne(o, s - 1),
    before: T(o, s - 1)
  }), o && i && c.disabled.push({
    after: o,
    before: T(o, s - 1)
  }), !o && i && c.disabled.push({
    after: Ne(i, s - 1),
    before: T(i, s - 1)
  })), u) {
    if (o && !i && (c.disabled.push({
      before: T(o, -u + 1)
    }), c.disabled.push({
      after: T(o, u - 1)
    })), o && i) {
      var f = q(i, o) + 1, g = u - f;
      c.disabled.push({
        before: Ne(o, g)
      }), c.disabled.push({
        after: T(i, g)
      });
    }
    !o && i && (c.disabled.push({
      before: T(i, -u + 1)
    }), c.disabled.push({
      after: T(i, u - 1)
    }));
  }
  return l(He.Provider, { value: { selected: r, onDayClick: d, modifiers: c }, children: n });
}
function je() {
  var e = Z(He);
  if (!e)
    throw new Error("useSelectRange must be used within a SelectRangeProvider");
  return e;
}
function be(e) {
  return Array.isArray(e) ? dt([], e, !0) : e !== void 0 ? [e] : [];
}
function Lr(e) {
  var t = {};
  return Object.entries(e).forEach(function(n) {
    var r = n[0], a = n[1];
    t[r] = be(a);
  }), t;
}
var H;
(function(e) {
  e.Outside = "outside", e.Disabled = "disabled", e.Selected = "selected", e.Hidden = "hidden", e.Today = "today", e.RangeStart = "range_start", e.RangeEnd = "range_end", e.RangeMiddle = "range_middle";
})(H || (H = {}));
var Er = H.Selected, X = H.Disabled, Ir = H.Hidden, Rr = H.Today, xe = H.RangeEnd, Pe = H.RangeMiddle, Oe = H.RangeStart, Br = H.Outside;
function Hr(e, t, n) {
  var r, a = (r = {}, r[Er] = be(e.selected), r[X] = be(e.disabled), r[Ir] = be(e.hidden), r[Rr] = [e.today], r[xe] = [], r[Pe] = [], r[Oe] = [], r[Br] = [], r);
  return e.fromDate && a[X].push({ before: e.fromDate }), e.toDate && a[X].push({ after: e.toDate }), ce(e) ? a[X] = a[X].concat(t.modifiers[X]) : de(e) && (a[X] = a[X].concat(n.modifiers[X]), a[Oe] = n.modifiers[Oe], a[Pe] = n.modifiers[Pe], a[xe] = n.modifiers[xe]), a;
}
var yt = z(void 0);
function jr(e) {
  var t = N(), n = Be(), r = je(), a = Hr(t, n, r), o = Lr(t.modifiers), i = w(w({}, a), o);
  return l(yt.Provider, { value: i, children: e.children });
}
function gt() {
  var e = Z(yt);
  if (!e)
    throw new Error("useModifiers must be used within a ModifiersProvider");
  return e;
}
function Ar(e) {
  return !!(e && typeof e == "object" && "before" in e && "after" in e);
}
function qr(e) {
  return !!(e && typeof e == "object" && "from" in e);
}
function $r(e) {
  return !!(e && typeof e == "object" && "after" in e);
}
function Qr(e) {
  return !!(e && typeof e == "object" && "before" in e);
}
function Xr(e) {
  return !!(e && typeof e == "object" && "dayOfWeek" in e);
}
function Gr(e, t) {
  var n, r = t.from, a = t.to;
  if (r && a) {
    var o = q(a, r) < 0;
    o && (n = [a, r], r = n[0], a = n[1]);
    var i = q(e, r) >= 0 && q(a, e) >= 0;
    return i;
  }
  return a ? Y(a, e) : r ? Y(r, e) : !1;
}
function Vr(e) {
  return Fe(e);
}
function Ur(e) {
  return Array.isArray(e) && e.every(Fe);
}
function Jr(e, t) {
  return t.some(function(n) {
    if (typeof n == "boolean")
      return n;
    if (Vr(n))
      return Y(e, n);
    if (Ur(n))
      return n.includes(e);
    if (qr(n))
      return Gr(e, n);
    if (Xr(n))
      return n.dayOfWeek.includes(e.getDay());
    if (Ar(n)) {
      var r = q(n.before, e), a = q(n.after, e), o = r > 0, i = a < 0, s = Te(n.before, n.after);
      return s ? i && o : o || i;
    }
    return $r(n) ? q(e, n.after) > 0 : Qr(n) ? q(n.before, e) > 0 : typeof n == "function" ? n(e) : !1;
  });
}
function Ae(e, t, n) {
  var r = Object.keys(t).reduce(function(o, i) {
    var s = t[i];
    return Jr(e, s) && o.push(i), o;
  }, []), a = {};
  return r.forEach(function(o) {
    return a[o] = !0;
  }), n && !Ie(e, n) && (a.outside = !0), a;
}
function Kr(e, t) {
  for (var n = F(e[0]), r = Le(e[e.length - 1]), a, o, i = n; i <= r; ) {
    var s = Ae(i, t), u = !s.disabled && !s.hidden;
    if (!u) {
      i = T(i, 1);
      continue;
    }
    if (s.selected)
      return i;
    s.today && !o && (o = i), a || (a = i), i = T(i, 1);
  }
  return o || a;
}
var zr = 365;
function bt(e, t) {
  var n = t.moveBy, r = t.direction, a = t.context, o = t.modifiers, i = t.retry, s = i === void 0 ? { count: 0, lastFocused: e } : i, u = a.weekStartsOn, d = a.fromDate, c = a.toDate, f = a.locale, g = {
    day: T,
    week: We,
    month: B,
    year: Bt,
    startOfWeek: function(b) {
      return a.ISOWeek ? K(b) : $(b, { locale: f, weekStartsOn: u });
    },
    endOfWeek: function(b) {
      return a.ISOWeek ? at(b) : Ee(b, { locale: f, weekStartsOn: u });
    }
  }, h = g[n](e, r === "after" ? 1 : -1);
  r === "before" && d ? h = Ht([d, h]) : r === "after" && c && (h = jt([c, h]));
  var y = !0;
  if (o) {
    var M = Ae(h, o);
    y = !M.disabled && !M.hidden;
  }
  return y ? h : s.count > zr ? s.lastFocused : bt(h, {
    moveBy: n,
    direction: r,
    context: a,
    modifiers: o,
    retry: w(w({}, s), { count: s.count + 1 })
  });
}
var wt = z(void 0);
function Zr(e) {
  var t = fe(), n = gt(), r = E(), a = r[0], o = r[1], i = E(), s = i[0], u = i[1], d = Kr(t.displayMonths, n), c = a ?? (s && t.isDateDisplayed(s)) ? s : d, f = function() {
    u(a), o(void 0);
  }, g = function(b) {
    o(b);
  }, h = N(), y = function(b, _) {
    if (a) {
      var p = bt(a, {
        moveBy: b,
        direction: _,
        context: h,
        modifiers: n
      });
      Y(a, p) || (t.goToDate(p, a), g(p));
    }
  }, M = {
    focusedDay: a,
    focusTarget: c,
    blur: f,
    focus: g,
    focusDayAfter: function() {
      return y("day", "after");
    },
    focusDayBefore: function() {
      return y("day", "before");
    },
    focusWeekAfter: function() {
      return y("week", "after");
    },
    focusWeekBefore: function() {
      return y("week", "before");
    },
    focusMonthBefore: function() {
      return y("month", "before");
    },
    focusMonthAfter: function() {
      return y("month", "after");
    },
    focusYearBefore: function() {
      return y("year", "before");
    },
    focusYearAfter: function() {
      return y("year", "after");
    },
    focusStartOfWeek: function() {
      return y("startOfWeek", "before");
    },
    focusEndOfWeek: function() {
      return y("endOfWeek", "after");
    }
  };
  return l(wt.Provider, { value: M, children: e.children });
}
function qe() {
  var e = Z(wt);
  if (!e)
    throw new Error("useFocusContext must be used within a FocusProvider");
  return e;
}
function ea(e, t) {
  var n = gt(), r = Ae(e, n, t);
  return r;
}
var $e = z(void 0);
function ta(e) {
  if (!Me(e.initialProps)) {
    var t = {
      selected: void 0
    };
    return l($e.Provider, { value: t, children: e.children });
  }
  return l(na, { initialProps: e.initialProps, children: e.children });
}
function na(e) {
  var t = e.initialProps, n = e.children, r = function(o, i, s) {
    var u, d, c;
    if ((u = t.onDayClick) === null || u === void 0 || u.call(t, o, i, s), i.selected && !t.required) {
      (d = t.onSelect) === null || d === void 0 || d.call(t, void 0, o, i, s);
      return;
    }
    (c = t.onSelect) === null || c === void 0 || c.call(t, o, o, i, s);
  }, a = {
    selected: t.selected,
    onDayClick: r
  };
  return l($e.Provider, { value: a, children: n });
}
function pt() {
  var e = Z($e);
  if (!e)
    throw new Error("useSelectSingle must be used within a SelectSingleProvider");
  return e;
}
function ra(e, t) {
  var n = N(), r = pt(), a = Be(), o = je(), i = qe(), s = i.focusDayAfter, u = i.focusDayBefore, d = i.focusWeekAfter, c = i.focusWeekBefore, f = i.blur, g = i.focus, h = i.focusMonthBefore, y = i.focusMonthAfter, M = i.focusYearBefore, b = i.focusYearAfter, _ = i.focusStartOfWeek, p = i.focusEndOfWeek, C = function(m) {
    var v, x, ne, W;
    Me(n) ? (v = r.onDayClick) === null || v === void 0 || v.call(r, e, t, m) : ce(n) ? (x = a.onDayClick) === null || x === void 0 || x.call(a, e, t, m) : de(n) ? (ne = o.onDayClick) === null || ne === void 0 || ne.call(o, e, t, m) : (W = n.onDayClick) === null || W === void 0 || W.call(n, e, t, m);
  }, j = function(m) {
    var v;
    g(e), (v = n.onDayFocus) === null || v === void 0 || v.call(n, e, t, m);
  }, P = function(m) {
    var v;
    f(), (v = n.onDayBlur) === null || v === void 0 || v.call(n, e, t, m);
  }, Q = function(m) {
    var v;
    (v = n.onDayMouseEnter) === null || v === void 0 || v.call(n, e, t, m);
  }, O = function(m) {
    var v;
    (v = n.onDayMouseLeave) === null || v === void 0 || v.call(n, e, t, m);
  }, oe = function(m) {
    var v;
    (v = n.onDayPointerEnter) === null || v === void 0 || v.call(n, e, t, m);
  }, he = function(m) {
    var v;
    (v = n.onDayPointerLeave) === null || v === void 0 || v.call(n, e, t, m);
  }, me = function(m) {
    var v;
    (v = n.onDayTouchCancel) === null || v === void 0 || v.call(n, e, t, m);
  }, G = function(m) {
    var v;
    (v = n.onDayTouchEnd) === null || v === void 0 || v.call(n, e, t, m);
  }, ve = function(m) {
    var v;
    (v = n.onDayTouchMove) === null || v === void 0 || v.call(n, e, t, m);
  }, De = function(m) {
    var v;
    (v = n.onDayTouchStart) === null || v === void 0 || v.call(n, e, t, m);
  }, _e = function(m) {
    var v;
    (v = n.onDayKeyUp) === null || v === void 0 || v.call(n, e, t, m);
  }, ye = function(m) {
    var v;
    switch (m.key) {
      case "ArrowLeft":
        m.preventDefault(), m.stopPropagation(), n.dir === "rtl" ? s() : u();
        break;
      case "ArrowRight":
        m.preventDefault(), m.stopPropagation(), n.dir === "rtl" ? u() : s();
        break;
      case "ArrowDown":
        m.preventDefault(), m.stopPropagation(), d();
        break;
      case "ArrowUp":
        m.preventDefault(), m.stopPropagation(), c();
        break;
      case "PageUp":
        m.preventDefault(), m.stopPropagation(), m.shiftKey ? M() : h();
        break;
      case "PageDown":
        m.preventDefault(), m.stopPropagation(), m.shiftKey ? b() : y();
        break;
      case "Home":
        m.preventDefault(), m.stopPropagation(), _();
        break;
      case "End":
        m.preventDefault(), m.stopPropagation(), p();
        break;
    }
    (v = n.onDayKeyDown) === null || v === void 0 || v.call(n, e, t, m);
  }, te = {
    onClick: C,
    onFocus: j,
    onBlur: P,
    onKeyDown: ye,
    onKeyUp: _e,
    onMouseEnter: Q,
    onMouseLeave: O,
    onPointerEnter: oe,
    onPointerLeave: he,
    onTouchCancel: me,
    onTouchEnd: G,
    onTouchMove: ve,
    onTouchStart: De
  };
  return te;
}
function aa() {
  var e = N(), t = pt(), n = Be(), r = je(), a = Me(e) ? t.selected : ce(e) ? n.selected : de(e) ? r.selected : void 0;
  return a;
}
function oa(e) {
  return Object.values(H).includes(e);
}
function ia(e, t) {
  var n = [e.classNames.day];
  return Object.keys(t).forEach(function(r) {
    var a = e.modifiersClassNames[r];
    if (a)
      n.push(a);
    else if (oa(r)) {
      var o = e.classNames["day_".concat(r)];
      o && n.push(o);
    }
  }), n;
}
function sa(e, t) {
  var n = w({}, e.styles.day);
  return Object.keys(t).forEach(function(r) {
    var a;
    n = w(w({}, n), (a = e.modifiersStyles) === null || a === void 0 ? void 0 : a[r]);
  }), n;
}
function ua(e, t, n) {
  var r, a, o, i = N(), s = qe(), u = ea(e, t), d = ra(e, u), c = aa(), f = !!(i.onDayClick || i.mode !== "default");
  U(function() {
    var Q;
    u.outside || s.focusedDay && f && Y(s.focusedDay, e) && ((Q = n.current) === null || Q === void 0 || Q.focus());
  }, [
    s.focusedDay,
    e,
    n,
    f,
    u.outside
  ]);
  var g = ia(i, u).join(" "), h = sa(i, u), y = !!(u.outside && !i.showOutsideDays || u.hidden), M = (o = (a = i.components) === null || a === void 0 ? void 0 : a.DayContent) !== null && o !== void 0 ? o : Or, b = l(M, { date: e, displayMonth: t, activeModifiers: u }), _ = {
    style: h,
    className: g,
    children: b,
    role: "gridcell"
  }, p = s.focusTarget && Y(s.focusTarget, e) && !u.outside, C = s.focusedDay && Y(s.focusedDay, e), j = w(w(w({}, _), (r = { disabled: u.disabled, role: "gridcell" }, r["aria-selected"] = u.selected, r.tabIndex = C || p ? 0 : -1, r)), d), P = {
    isButton: f,
    isHidden: y,
    activeModifiers: u,
    selectedDays: c,
    buttonProps: j,
    divProps: _
  };
  return P;
}
function la(e) {
  var t = _t(null), n = ua(e.date, e.displayMonth, t);
  return n.isHidden ? l("div", { role: "gridcell" }) : n.isButton ? l(pe, w({ name: "day", ref: t }, n.buttonProps)) : l("div", w({}, n.divProps));
}
function ca(e) {
  var t = e.number, n = e.dates, r = N(), a = r.onWeekNumberClick, o = r.styles, i = r.classNames, s = r.locale, u = r.labels.labelWeekNumber, d = r.formatters.formatWeekNumber, c = d(Number(t), { locale: s });
  if (!a)
    return l("span", { className: i.weeknumber, style: o.weeknumber, children: c });
  var f = u(Number(t), { locale: s }), g = function(h) {
    a(t, n, h);
  };
  return l(pe, { name: "week-number", "aria-label": f, className: i.weeknumber, style: o.weeknumber, onClick: g, children: c });
}
function da(e) {
  var t, n, r = N(), a = r.styles, o = r.classNames, i = r.showWeekNumber, s = r.components, u = (t = s == null ? void 0 : s.Day) !== null && t !== void 0 ? t : la, d = (n = s == null ? void 0 : s.WeekNumber) !== null && n !== void 0 ? n : ca, c;
  return i && (c = l("td", { className: o.cell, style: a.cell, children: l(d, { number: e.weekNumber, dates: e.dates }) })), S("tr", { className: o.row, style: a.row, children: [c, e.dates.map(function(f) {
    return l("td", { className: o.cell, style: a.cell, role: "presentation", children: l(u, { displayMonth: e.displayMonth, date: f }) }, jn(f));
  })] });
}
function Ze(e, t, n) {
  for (var r = n != null && n.ISOWeek ? at(t) : Ee(t, n), a = n != null && n.ISOWeek ? K(e) : $(e, n), o = q(r, a), i = [], s = 0; s <= o; s++)
    i.push(T(a, s));
  var u = i.reduce(function(d, c) {
    var f = n != null && n.ISOWeek ? it(c) : ut(c, n), g = d.find(function(h) {
      return h.weekNumber === f;
    });
    return g ? (g.dates.push(c), d) : (d.push({
      weekNumber: f,
      dates: [c]
    }), d);
  }, []);
  return u;
}
function fa(e, t) {
  var n = Ze(F(e), Le(e), t);
  if (t != null && t.useFixedWeeks) {
    var r = qn(e, t);
    if (r < 6) {
      var a = n[n.length - 1], o = a.dates[a.dates.length - 1], i = We(o, 6 - r), s = Ze(We(o, 1), i, t);
      n.push.apply(n, s);
    }
  }
  return n;
}
function ha(e) {
  var t, n, r, a = N(), o = a.locale, i = a.classNames, s = a.styles, u = a.hideHead, d = a.fixedWeeks, c = a.components, f = a.weekStartsOn, g = a.firstWeekContainsDate, h = a.ISOWeek, y = fa(e.displayMonth, {
    useFixedWeeks: !!d,
    ISOWeek: h,
    locale: o,
    weekStartsOn: f,
    firstWeekContainsDate: g
  }), M = (t = c == null ? void 0 : c.Head) !== null && t !== void 0 ? t : Pr, b = (n = c == null ? void 0 : c.Row) !== null && n !== void 0 ? n : da, _ = (r = c == null ? void 0 : c.Footer) !== null && r !== void 0 ? r : Nr;
  return S("table", { id: e.id, className: i.table, style: s.table, role: "grid", "aria-labelledby": e["aria-labelledby"], children: [!u && l(M, {}), l("tbody", { className: i.tbody, style: s.tbody, children: y.map(function(p) {
    return l(b, { displayMonth: e.displayMonth, dates: p.dates, weekNumber: p.weekNumber }, p.weekNumber);
  }) }), l(_, { displayMonth: e.displayMonth })] });
}
function ma() {
  return !!(typeof window < "u" && window.document && window.document.createElement);
}
var va = ma() ? Dt : U, Se = !1, ya = 0;
function et() {
  return "react-day-picker-".concat(++ya);
}
function ga(e) {
  var t, n = e ?? (Se ? et() : null), r = E(n), a = r[0], o = r[1];
  return va(function() {
    a === null && o(et());
  }, []), U(function() {
    Se === !1 && (Se = !0);
  }, []), (t = e ?? a) !== null && t !== void 0 ? t : void 0;
}
function ba(e) {
  var t, n, r = N(), a = r.dir, o = r.classNames, i = r.styles, s = r.components, u = fe().displayMonths, d = ga(r.id ? "".concat(r.id, "-").concat(e.displayIndex) : void 0), c = r.id ? "".concat(r.id, "-grid-").concat(e.displayIndex) : void 0, f = [o.month], g = i.month, h = e.displayIndex === 0, y = e.displayIndex === u.length - 1, M = !h && !y;
  a === "rtl" && (t = [h, y], y = t[0], h = t[1]), h && (f.push(o.caption_start), g = w(w({}, g), i.caption_start)), y && (f.push(o.caption_end), g = w(w({}, g), i.caption_end)), M && (f.push(o.caption_between), g = w(w({}, g), i.caption_between));
  var b = (n = s == null ? void 0 : s.Caption) !== null && n !== void 0 ? n : kr;
  return S("div", { className: f.join(" "), style: g, children: [l(b, { id: d, displayMonth: e.displayMonth, displayIndex: e.displayIndex }), l(ha, { id: c, "aria-labelledby": d, displayMonth: e.displayMonth })] }, e.displayIndex);
}
function wa(e) {
  var t = N(), n = t.classNames, r = t.styles;
  return l("div", { className: n.months, style: r.months, children: e.children });
}
function pa(e) {
  var t, n, r = e.initialProps, a = N(), o = qe(), i = fe(), s = E(!1), u = s[0], d = s[1];
  U(function() {
    a.initialFocus && o.focusTarget && (u || (o.focus(o.focusTarget), d(!0)));
  }, [
    a.initialFocus,
    u,
    o.focus,
    o.focusTarget,
    o
  ]);
  var c = [a.classNames.root, a.className];
  a.numberOfMonths > 1 && c.push(a.classNames.multiple_months), a.showWeekNumber && c.push(a.classNames.with_weeknumber);
  var f = w(w({}, a.styles.root), a.style), g = Object.keys(r).filter(function(y) {
    return y.startsWith("data-");
  }).reduce(function(y, M) {
    var b;
    return w(w({}, y), (b = {}, b[M] = r[M], b));
  }, {}), h = (n = (t = r.components) === null || t === void 0 ? void 0 : t.Months) !== null && n !== void 0 ? n : wa;
  return l("div", w({ className: c.join(" "), style: f, dir: a.dir, id: a.id, nonce: r.nonce, title: r.title, lang: r.lang }, g, { children: l(h, { children: i.displayMonths.map(function(y, M) {
    return l(ba, { displayIndex: M, displayMonth: y }, M);
  }) }) }));
}
function Ma(e) {
  var t = e.children, n = Qn(e, ["children"]);
  return l(cr, { initialProps: n, children: l(pr, { children: l(ta, { initialProps: n, children: l(Sr, { initialProps: n, children: l(Yr, { initialProps: n, children: l(jr, { children: l(Zr, { children: t }) }) }) }) }) }) });
}
function Da(e) {
  return l(Ma, w({}, e, { children: l(pa, { initialProps: e }) }));
}
const _a = [
  { label: "3 Hour", value: "3hour" },
  { label: "12 Hour", value: "12hour" },
  { label: "1 Day", value: "1day" },
  { label: "1 Week", value: "7day" },
  { label: "1 Month", value: "1month" }
], ka = Array.from({ length: 12 }, (e, t) => t).map((e) => e < 9 ? { label: `0${e + 1}`, value: `${e + 1}` } : { label: `${e + 1}`, value: `${e + 1}` }), Na = Array.from({ length: 60 }, (e, t) => t).map((e) => e < 10 ? { label: `0${e}`, value: `${e}` } : { label: `${e}`, value: `${e}` });
function Ia({
  startTime: e,
  endTime: t,
  minTime: n = 0,
  maxTime: r = 1 / 0,
  options: a = _a,
  hideTime: o = !1,
  hideSeek: i = !1,
  hideRange: s = !1,
  interval: u = "7day",
  rangesOnly: d = !1,
  timezone: c = L.tz.guess(),
  deltaChangePercent: f = 0.5,
  onChange: g,
  style: h
}) {
  const [y, M] = E(e ?? L().subtract(7, "days").unix()), [b, _] = E(t ?? L().unix()), [p, C] = E(u), j = kt(() => Math.floor((b - y) * f), [b, y, f]);
  U(() => {
    e && t && (M(e), _(t));
  }, [e, t]), U(() => {
    ve(u);
  }, [u]), U(() => {
    g && g(y, b, p);
  }, [y, b, p]);
  const [P, Q] = E(null), [O, oe] = E(null), he = L.unix(y), me = L.unix(b), G = p === "custom";
  function ve(m) {
    if (C(m), m === "custom")
      return;
    const v = Ct(m);
    n && v.startTime < n || r && v.endTime > r || (M(v.startTime), _(v.endTime));
  }
  function De(m) {
    const v = L.unix((P == null ? void 0 : P.id) === "startTime" ? y : b), x = L(m).tz(c);
    if (x.set("hour", v.hour()), x.set("minute", v.minute()), x.set("second", 0), (P == null ? void 0 : P.id) === "startTime") {
      if (x.unix() > b)
        return;
      M(x.unix());
    } else if ((P == null ? void 0 : P.id) === "endTime") {
      if (x.unix() < y)
        return;
      _(x.unix());
    }
  }
  const _e = (m) => {
    const [v, x, ne] = [...m.split(" ")[0].split(":"), m.split(" ")[1]], W = L.unix((O == null ? void 0 : O.id) === "startTime" ? y : b).tz(c);
    if (W.set("hour", +v), W.set("minute", +x), W.set("second", 0), ne === "PM" && +v < 12 ? W.set("hour", +v + 12) : ne === "AM" && +v == 12 && W.set("hour", 0), (O == null ? void 0 : O.id) === "startTime") {
      if (W.unix() > b || W.unix() < n)
        return;
      M(W.unix());
    } else if ((O == null ? void 0 : O.id) === "endTime") {
      if (W.unix() < y || W.unix() > r)
        return;
      _(W.unix());
    }
  }, ye = (m, v) => {
    const x = v * m;
    m === 1 && b + x > L().unix() || m === -1 && y + x < n || m === 1 && b + x > r || (M(y + x), _(b + x));
  }, te = G ? `${A.date}` : `${A.date} ${A.nohover}`;
  return /* @__PURE__ */ S("div", { className: A.container, style: { ...h }, children: [
    !s && /* @__PURE__ */ S(I, { children: [
      /* @__PURE__ */ S("div", { className: A.range, children: [
        /* @__PURE__ */ l(Nt, { color: h == null ? void 0 : h.color }),
        /* @__PURE__ */ l(ge, { id: "range-select", style: { ...h }, options: [...a, { label: "Custom", value: "custom" }], value: p, onChange: ve })
      ] }),
      !d && /* @__PURE__ */ l(xt, { style: {
        background: h == null ? void 0 : h.color
      }, orientation: "vertical", flexItem: !0 })
    ] }),
    d ? null : /* @__PURE__ */ S(I, { children: [
      !i && /* @__PURE__ */ l(tt, { direction: -1, distance: j, onSeek: ye, color: h == null ? void 0 : h.color }),
      /* @__PURE__ */ l("span", { className: te, id: "startTime", onClick: (m) => Q(G ? m.currentTarget : null), children: he.tz(c).format("MMM DD, YYYY") }),
      !o && /* @__PURE__ */ l("span", { className: te, id: "startTime", onClick: (m) => oe(G ? m.currentTarget : null), children: he.tz(c).format("hh:mm A") }),
      " -",
      /* @__PURE__ */ l("span", { className: te, id: "endTime", onClick: (m) => Q(G ? m.currentTarget : null), children: me.tz(c).format("MMM DD, YYYY") }),
      !o && /* @__PURE__ */ l("span", { className: te, id: "endTime", onClick: (m) => oe(G ? m.currentTarget : null), children: me.tz(c).format("hh:mm A") }),
      !i && /* @__PURE__ */ l(tt, { direction: 1, distance: j, onSeek: ye, color: h == null ? void 0 : h.color }),
      G && /* @__PURE__ */ S(I, { children: [
        /* @__PURE__ */ l(Qe, { className: A.menu, open: !!P, anchorEl: P, onClose: () => Q(null), children: /* @__PURE__ */ l(
          Ca,
          {
            value: L.unix((P == null ? void 0 : P.id) === "startTime" ? y : b).toDate(),
            onSelect: De
          }
        ) }),
        /* @__PURE__ */ l(Qe, { className: A.menu, open: !!O, anchorEl: O, onClose: () => oe(null), children: /* @__PURE__ */ l(
          xa,
          {
            value: L.unix((O == null ? void 0 : O.id) === "startTime" ? y : b).tz(c).format("hh:mm A"),
            onChange: _e
          }
        ) })
      ] })
    ] })
  ] });
}
function Ca({ value: e, onSelect: t }) {
  return /* @__PURE__ */ l(I, { children: /* @__PURE__ */ l(
    Da,
    {
      mode: "single",
      disabled: {
        after: L().toDate()
      },
      defaultMonth: e,
      selected: e,
      onSelect: (n) => {
        n && t(n);
      }
    }
  ) });
}
function xa({ value: e, onChange: t }) {
  const [n, r] = E(+e.split(":")[0]), [a, o] = E(+e.split(":")[1].split(" ")[0]), [i, s] = E(e.split(" ")[1].toLowerCase());
  return U(() => {
    const u = `${n}:${a} ${i.toUpperCase()}`;
    t(u);
  }, [n, a, i, t, e]), /* @__PURE__ */ S("div", { className: `${A.time} ${A.selector}`, children: [
    /* @__PURE__ */ l(ge, { label: "Hour", value: n.toString(), options: ka, onChange: (u) => r(+u) }),
    /* @__PURE__ */ l(ge, { label: "Minute", value: a.toString(), options: Na, onChange: (u) => o(+u) }),
    /* @__PURE__ */ l(ge, { label: "AM/PM", value: i, options: [{ label: "AM", value: "am" }, { label: "PM", value: "pm" }], onChange: (u) => s(u) })
  ] });
}
function tt({ direction: e, distance: t, color: n, onSeek: r }) {
  return /* @__PURE__ */ l(
    "span",
    {
      className: A.seek,
      title: `Seek ${L.duration(t, "seconds").humanize()} ${e === -1 ? "backward" : "forward"}`,
      onClick: () => r(e, t),
      children: /* @__PURE__ */ l(Pt, { direction: e, color: n })
    }
  );
}
export {
  Ia as default
};
