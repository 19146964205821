import * as S from "react";
const w = S.createContext(void 0), H = {};
w.Provider;
const K = () => S.useContext(w) ? S.useContext(w) : H;
function G(e) {
  if (!Array.isArray(e))
    return [e];
  if (process.env.NODE_ENV !== "production" && e.length > 2)
    throw new Error("CSS Bucket contains an entry with greater than 2 items, please report this to https://github.com/microsoft/griffel/issues");
  return e;
}
const N = typeof window > "u" ? global : window, v = "@griffel/";
function V(e, t) {
  return N[Symbol.for(v + e)] || (N[Symbol.for(v + e)] = t), N[Symbol.for(v + e)];
}
const X = /* @__PURE__ */ V("DEBUG_RESET_CLASSES", {}), x = /* @__PURE__ */ V("DEFINITION_LOOKUP_TABLE", {}), p = "data-make-styles-bucket", Q = "data-priority", W = "r", C = 7, E = "___", B = "_", j = process.env.NODE_ENV === "production" ? E.length + C : E.length + C + B.length + C, J = 0, A = 1;
function Y(e, t, n, s) {
  const o = [];
  if (s[p] = t, s[Q] = String(n), e)
    for (const i in s)
      e.setAttribute(i, s[i]);
  function r(i) {
    return e != null && e.sheet ? e.sheet.insertRule(i, e.sheet.cssRules.length) : o.push(i);
  }
  return {
    elementAttributes: s,
    insertRule: r,
    element: e,
    bucketName: t,
    cssRules() {
      return e != null && e.sheet ? Array.from(e.sheet.cssRules).map((i) => i.cssText) : o;
    }
  };
}
const Z = [
  // reset styles
  "r",
  // catch-all
  "d",
  // link
  "l",
  // visited
  "v",
  // focus-within
  "w",
  // focus
  "f",
  // focus-visible
  "i",
  // hover
  "h",
  // active
  "a",
  // at rules for reset styles
  "s",
  // keyframes
  "k",
  // at-rules
  "t",
  // @media rules
  "m",
  // @container rules
  "c"
], D = /* @__PURE__ */ Z.reduce((e, t, n) => (e[t] = n, e), {});
function ee(e, t, n) {
  return (e === "m" ? e + t : e) + n;
}
function te(e, t, n, s, o = {}) {
  var r, i;
  const c = e === "m", l = (r = o.m) != null ? r : "0", f = (i = o.p) != null ? i : 0, u = ee(e, l, f);
  if (!s.stylesheets[u]) {
    const h = t && t.createElement("style"), a = Y(h, e, f, Object.assign({}, s.styleElementAttributes, c && {
      media: l
    }));
    s.stylesheets[u] = a, t && h && t.head.insertBefore(h, se(t, n, e, s, o));
  }
  return s.stylesheets[u];
}
function ne(e, t, n) {
  var s, o;
  const r = t + ((s = n.m) != null ? s : ""), i = e.getAttribute(p) + ((o = e.media) != null ? o : "");
  return r === i;
}
function se(e, t, n, s, o = {}) {
  var r, i;
  const c = D[n], l = (r = o.m) != null ? r : "", f = (i = o.p) != null ? i : 0;
  let u = (g) => c - D[g.getAttribute(p)], h = e.head.querySelectorAll(`[${p}]`);
  if (n === "m") {
    const g = e.head.querySelectorAll(`[${p}="${n}"]`);
    g.length && (h = g, u = (M) => s.compareMediaQueries(l, M.media));
  }
  const a = (g) => ne(g, n, o) ? f - Number(g.getAttribute("data-priority")) : u(g), d = h.length;
  let m = d - 1;
  for (; m >= 0; ) {
    const g = h.item(m);
    if (a(g) > 0)
      return g.nextSibling;
    m--;
  }
  return d > 0 ? h.item(0) : t ? t.nextSibling : null;
}
const re = /* @__PURE__ */ ["-moz-placeholder", "-moz-focus-inner", "-moz-focusring", "-ms-input-placeholder", "-moz-read-write", "-moz-read-only"].join("|"), oe = /* @__PURE__ */ new RegExp(`:(${re})`);
function T(e, t) {
  try {
    e.insertRule(t);
  } catch (n) {
    process.env.NODE_ENV !== "production" && !oe.test(t) && console.error(`There was a problem inserting the following rule: "${t}"`, n);
  }
}
const y = /* @__PURE__ */ (() => {
  try {
    var e;
    return !!(typeof window < "u" && ((e = window.sessionStorage) != null && e.getItem("__GRIFFEL_DEVTOOLS__")));
  } catch {
    return !1;
  }
})();
function b(e) {
  for (var t = 0, n, s = 0, o = e.length; o >= 4; ++s, o -= 4)
    n = e.charCodeAt(s) & 255 | (e.charCodeAt(++s) & 255) << 8 | (e.charCodeAt(++s) & 255) << 16 | (e.charCodeAt(++s) & 255) << 24, n = /* Math.imul(k, m): */
    (n & 65535) * 1540483477 + ((n >>> 16) * 59797 << 16), n ^= /* k >>> r: */
    n >>> 24, t = /* Math.imul(k, m): */
    (n & 65535) * 1540483477 + ((n >>> 16) * 59797 << 16) ^ /* Math.imul(h, m): */
    (t & 65535) * 1540483477 + ((t >>> 16) * 59797 << 16);
  switch (o) {
    case 3:
      t ^= (e.charCodeAt(s + 2) & 255) << 16;
    case 2:
      t ^= (e.charCodeAt(s + 1) & 255) << 8;
    case 1:
      t ^= e.charCodeAt(s) & 255, t = /* Math.imul(h, m): */
      (t & 65535) * 1540483477 + ((t >>> 16) * 59797 << 16);
  }
  return t ^= t >>> 13, t = /* Math.imul(h, m): */
  (t & 65535) * 1540483477 + ((t >>> 16) * 59797 << 16), ((t ^ t >>> 15) >>> 0).toString(36);
}
function O(e) {
  const t = e.length;
  if (t === C)
    return e;
  for (let n = t; n < C; n++)
    e += "0";
  return e;
}
function k(e, t, n = []) {
  return process.env.NODE_ENV === "production" ? E + O(b(e + t)) : E + O(b(e + t)) + B + O(b(n.join("")));
}
function P(e, t) {
  let n = "", s = "";
  for (const o in e) {
    const r = e[o];
    if (r === 0) {
      s += o + " ";
      continue;
    }
    const i = Array.isArray(r), c = t === "rtl" ? (i ? r[1] : r) + " " : (i ? r[0] : r) + " ";
    n += c, s += c;
  }
  return [n.slice(0, -1), s.slice(0, -1)];
}
function F(e, t) {
  const n = {};
  for (const s in e) {
    const [o, r] = P(e[s], t);
    if (r === "") {
      n[s] = "";
      continue;
    }
    const i = k(r, t), c = i + (o === "" ? "" : " " + o);
    x[i] = [e[s], t], n[s] = c;
  }
  return n;
}
const R = {};
function ie() {
  let e = null, t = "", n = "";
  const s = new Array(arguments.length);
  let o = "";
  for (let a = 0; a < arguments.length; a++) {
    const d = arguments[a];
    if (typeof d == "string" && d !== "") {
      const m = d.indexOf(E);
      if (m === -1)
        process.env.NODE_ENV !== "production" && d.split(" ").forEach((g) => {
          g.startsWith(W) && X[g] && (o ? console.error(`mergeClasses(): a passed string contains multiple classes produced by makeResetStyles (${d} & ${t}, this will lead to non-deterministic behavior. Learn more:https://griffel.js.org/react/api/make-reset-styles#limitations
Source string: ${d}`) : o = g);
        }), t += d + " ";
      else {
        const g = d.substr(m, j);
        m > 0 && (t += d.slice(0, m)), n += g, s[a] = g;
      }
      process.env.NODE_ENV !== "production" && d.indexOf(E, m + 1) !== -1 && console.error(`mergeClasses(): a passed string contains multiple identifiers of atomic classes (classes that start with "${E}"), it's possible that passed classes were concatenated in a wrong way. Source string: ${d}`);
    }
  }
  if (n === "")
    return t.slice(0, -1);
  const r = R[n];
  if (r !== void 0)
    return t + r;
  const i = [];
  for (let a = 0; a < arguments.length; a++) {
    const d = s[a];
    if (d) {
      const m = x[d];
      m ? (i.push(m[J]), process.env.NODE_ENV !== "production" && e !== null && e !== m[A] && console.error(`mergeClasses(): a passed string contains an identifier (${d}) that has different direction (dir="${m[1] ? "rtl" : "ltr"}") setting than other classes. This is not supported. Source string: ${arguments[a]}`), e = m[A]) : process.env.NODE_ENV !== "production" && console.error(`mergeClasses(): a passed string contains an identifier (${d}) that does not match any entry in cache. Source string: ${arguments[a]}`);
    }
  }
  const c = Object.assign.apply(
    Object,
    // .assign() mutates the first object, we can't mutate mappings as it will produce invalid results later
    [{}].concat(i)
  ), [l, f] = P(c, e), u = k(f, e, s), h = u + " " + l;
  return R[n] = h, x[u] = [c, e], t + h;
}
const L = {}, $ = /* @__PURE__ */ new Set(), _ = {
  getChildrenSequences: (e) => {
    const t = Object.keys(R).find((n) => R[n].startsWith(e));
    return t ? t.split(E).filter((n) => n.length).map((n) => E + n) : [];
  },
  addCSSRule: (e) => {
    $.add(e);
  },
  addSequenceDetails: (e, t) => {
    Object.entries(e).forEach(([n, s]) => {
      L[s.substring(0, j)] = {
        slotName: n,
        sourceURL: t
      };
    });
  },
  getCSSRules: () => Array.from($),
  getSequenceDetails: (e) => L[e]
};
function q(e, t) {
  return Array.isArray(e) ? t === "rtl" ? e[1] : e[0] : e || "";
}
function ce(e, t, n, s) {
  const o = e[0], r = e[1];
  return Object.entries(o).map(([i, c]) => {
    const l = q(c, r);
    let f;
    if (n && t) {
      const u = n.find(({
        className: h
      }) => h === l);
      !u && t[0][i] ? f = q(t[0][i], t[1]) : u && t[0][i] ? f = (s ? s.filter(({
        debugClassNames: a
      }) => a.filter(({
        className: d
      }) => d === l).length > 0).length > 0 : !1) ? u.className : u.overriddenBy : (!u && !t[0][i] || u && !t[0][i]) && (f = void 0);
    }
    return {
      className: l,
      overriddenBy: f
    };
  });
}
function z(e, t) {
  const n = x[e];
  if (n === void 0)
    return;
  const s = t ? x[t.sequenceHash] : void 0, o = ce(n, s, t == null ? void 0 : t.debugClassNames, t == null ? void 0 : t.children), r = {
    sequenceHash: e,
    direction: n[1],
    children: [],
    debugClassNames: o
  };
  return _.getChildrenSequences(r.sequenceHash).reverse().forEach((c) => {
    const l = z(c, r);
    l && r.children.push(l);
  }), r.children.length || (r.rules = {}, r.debugClassNames.forEach(({
    className: c
  }) => {
    const l = _.getSequenceDetails(e);
    l && (r.slot = l.slotName, r.sourceURL = l.sourceURL);
    const f = _.getCSSRules().find((u) => u.includes(c));
    r.rules[c] = f;
  })), r;
}
function le(e) {
  const t = e.defaultView;
  if (!t || t.__GRIFFEL_DEVTOOLS__)
    return;
  const n = {
    getInfo: (s) => {
      const o = Array.from(s.classList).find((r) => r.startsWith(E));
      if (o !== void 0)
        return z(o);
    }
  };
  Object.defineProperty(t, "__GRIFFEL_DEVTOOLS__", {
    configurable: !1,
    enumerable: !1,
    get() {
      return n;
    }
  });
}
let ae = 0;
const ue = (e, t) => e < t ? -1 : e > t ? 1 : 0;
function fe(e = typeof document > "u" ? void 0 : document, t = {}) {
  const {
    unstable_filterCSSRule: n,
    insertionPoint: s,
    styleElementAttributes: o,
    compareMediaQueries: r = ue
  } = t, i = {
    insertionCache: {},
    stylesheets: {},
    styleElementAttributes: Object.freeze(o),
    compareMediaQueries: r,
    id: `d${ae++}`,
    insertCSSRules(c) {
      for (const l in c) {
        const f = c[l];
        for (let u = 0, h = f.length; u < h; u++) {
          const [a, d] = G(f[u]), m = te(l, e, s || null, i, d);
          i.insertionCache[a] || (i.insertionCache[a] = l, process.env.NODE_ENV !== "production" && y && _.addCSSRule(a), n ? n(a) && T(m, a) : T(m, a));
        }
      }
    }
  };
  return e && process.env.NODE_ENV !== "production" && y && le(e), i;
}
const de = () => {
  const e = {};
  return function(n, s) {
    e[n.id] === void 0 && (n.insertCSSRules(s), e[n.id] = !0);
  };
}, I = "<unknown>";
function he(e) {
  return Ee(e) || _e(e) || ye(e);
}
const me = /^\s*at (.*?) ?\(((?:file|https?|blob|chrome-extension|native|eval|webpack|<anonymous>|\/|[a-z]:\\|\\\\).*?)?\)?\s*$/i, ge = /^\s*at ()((?:file|https?|blob|chrome-extension|native|eval|webpack|<anonymous>|\/|[a-z]:\\|\\\\).*?)\s*$/i, Se = /\((\S*)\)/;
function Ee(e) {
  const t = me.exec(e) || ge.exec(e);
  if (!t)
    return null;
  let n = t[2];
  const s = n && n.indexOf("native") === 0, o = n && n.indexOf("eval") === 0, r = Se.exec(n);
  return o && r != null && (n = r[1]), {
    loc: s ? null : t[2],
    name: t[1] || I
  };
}
const pe = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)((?:file|https?|blob|chrome|webpack|resource|\[native).*?|[^@]*bundle)\s*$/i, Ce = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i;
function _e(e) {
  const t = pe.exec(e);
  if (!t)
    return null;
  let n = t[3];
  const s = n && n.indexOf(" > eval") > -1, o = Ce.exec(n);
  return s && o != null && (n = o[1]), {
    loc: t[3],
    name: t[1] || I
  };
}
const xe = /^\s*(?:([^@]*)(?:\((.*?)\))?@)?(\S.*?)\s*$/i;
function ye(e) {
  const t = xe.exec(e);
  return t ? {
    loc: t[3],
    name: t[1] || I
  } : null;
}
function Re() {
  const e = String(new Error().stack).split(`
`), t = Ne(e);
  if (t === void 0)
    return;
  const n = he(t);
  return n == null ? void 0 : n.loc;
}
function Ne(e) {
  for (let t = e.length - 1; t >= 0; --t)
    if (e[t].includes("at getSourceURLfromError"))
      return e[t + 3];
}
function ve(e, t, n = de) {
  const s = n();
  let o = null, r = null, i;
  process.env.NODE_ENV !== "production" && y && (i = Re());
  function c(l) {
    const {
      dir: f,
      renderer: u
    } = l, h = f === "ltr";
    h ? o === null && (o = F(e, f)) : r === null && (r = F(e, f)), s(u, t);
    const a = h ? o : r;
    return process.env.NODE_ENV !== "production" && y && _.addSequenceDetails(a, i), a;
  }
  return c;
}
function be() {
  return typeof window < "u" && !!(window.document && window.document.createElement);
}
const U = (
  // @ts-expect-error Hack to make sure that `useInsertionEffect` will not cause bundling issues in older React versions
  // eslint-disable-next-line no-useless-concat
  S["useInsertionEffect"] ? S["useInsertionEffect"] : void 0
), Oe = () => {
  const e = {};
  return function(n, s) {
    if (U && be()) {
      U(() => {
        n.insertCSSRules(s);
      }, [n, s]);
      return;
    }
    e[n.id] === void 0 && (n.insertCSSRules(s), e[n.id] = !0);
  };
}, we = /* @__PURE__ */ S.createContext(/* @__PURE__ */ fe());
function Ie() {
  return S.useContext(we);
}
const Ae = /* @__PURE__ */ S.createContext("ltr");
function De() {
  return S.useContext(Ae);
}
function Te(e, t) {
  const n = ve(e, t, Oe);
  return function() {
    const o = De(), r = Ie();
    return n({
      dir: o,
      renderer: r
    });
  };
}
const Fe = Te({
  root: {
    mc9l5x: "f1w7gpdv",
    Bg96gwp: "fez10in",
    ycbfsm: "fg4l7m0"
  },
  rtl: {
    Bz10aip: "f13rod7r"
  }
}, {
  d: [".f1w7gpdv{display:inline;}", ".fez10in{line-height:0;}", ".f13rod7r{-webkit-transform:scaleX(-1);-moz-transform:scaleX(-1);-ms-transform:scaleX(-1);transform:scaleX(-1);}"],
  t: ["@media (forced-colors: active){.fg4l7m0{forced-color-adjust:auto;}}"]
}), Le = (e, t) => {
  const {
    title: n,
    primaryFill: s = "currentColor",
    ...o
  } = e, r = {
    ...o,
    title: void 0,
    fill: s
  }, i = Fe(), c = K();
  return r.className = ie(i.root, (t == null ? void 0 : t.flipInRtl) && (c == null ? void 0 : c.textDirection) === "rtl" && i.rtl, r.className), n && (r["aria-label"] = n), !r["aria-label"] && !r["aria-labelledby"] ? r["aria-hidden"] = !0 : r.role = "img", r;
}, $e = (e, t, n, s) => {
  const o = t === "1em" ? "20" : t, r = S.forwardRef((i, c) => {
    const l = {
      ...Le(i, {
        flipInRtl: s == null ? void 0 : s.flipInRtl
      }),
      ref: c,
      width: t,
      height: t,
      viewBox: `0 0 ${o} ${o}`,
      xmlns: "http://www.w3.org/2000/svg"
    };
    return S.createElement("svg", l, ...n.map((f) => S.createElement("path", {
      d: f,
      fill: l.fill
    })));
  });
  return r.displayName = e, r;
};
export {
  $e as c
};
